const customVectorMapStyle = {
    mapLegend: {
		bottom: '25px',
        right: '30px',
        position: 'absolute',
        padding:' 0 15px 0'
    },
    mapLegendItem: {
        justifyContent: 'space-between',
        "&::after": {
            content: "''",
            borderBottom: '1px solid #d3d3d3',
            width: '100%',
            display: 'block',
            marginBottom: '5px'
        }
    }
}

export default customVectorMapStyle;