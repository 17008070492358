import React, { Component } from 'react';

import { VectorMap } from "react-jvectormap";
// import { Container } from './styles';

import { withStyles } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customVectorMapStyle from "assets/jss/material-dashboard-pro-react/views/customVectorMapStyle";

import compose from "utils/compose";

class CustomVectorMap extends Component {
    render() {
        const { mapColor, mapUsersQtt, mapUserPercent, statesProvinces, classes } = this.props;
        return (
            <GridContainer>
                <GridItem md={9}>
                    <VectorMap
                        map={"brazil"}
                        backgroundColor="white"
                        zoomOnScroll={false}
                        containerStyle={{
                            width: "100%",
                            height: "420px"
                        }}
                        containerClassName="map"
                        regionStyle={{
                            initial: {
                                fill: "#f1f1f1",
                                "fill-opacity": 0.9,
                                stroke: "#fff",
                                "stroke-width": 100,
                                "stroke-opacity": 1
                            }
                        }}
                        series={{
                            regions: [
                                {
                                    scale: {
                                        '1': '#1e3568',
                                    },
                                    values: mapColor,
                                    attribute: 'fill',
                                }
                            ]
                        }}
                        onRegionTipShow={
                            function (event, label, code) {
                                if (mapUsersQtt[code]) {
                                    label.html(
                                        '<b style="font-weight: 800; font-size: 14px;">' + mapUserPercent[code] + '%' + '</b>'
                                    );
                                }
                            }
                        }
                        regionLabelStyle={{
                            initial: {
                                'font-family': 'Roboto',
                                fill: '#fff'
                            },
                            hover: {
                                fill: 'black'
                            }
                        }}
                        labels={{
                            regions: {
                                render: function (code) {
                                    if (statesProvinces.indexOf(code) !== -1) {
                                        return code.toUpperCase();
                                    }
                                },
                            }
                        }}
                    />
                </GridItem>
                <GridItem md={3} className={classes.mapLegend} >
                    {Object.keys(mapUsersQtt).sort().map(key => [
                        <div key={key} className={classes.mapLegendItem}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '30px', fontWeight: 'bold' }}>{key.toUpperCase()}</div>
                                <div>{mapUsersQtt[key]}</div>
                            </div>
                        </div>
                    ])}
                </GridItem>
            </GridContainer>
        );
    }
}

export default compose(
    withStyles(customVectorMapStyle),
)(CustomVectorMap);