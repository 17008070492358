import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

import Alert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";

// @material-ui/core components
import { FormControl, withStyles } from "@material-ui/core";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Search from "@material-ui/icons/Search";
import DeleteIcon from '@material-ui/icons/Delete';

// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DocumentTitle from 'react-document-title';
import Loader from "components/Loader/Loader.jsx";
import CommonPage from "../../../components/Pages/CommonPage";
import Heading from "components/Heading/Heading";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx';

// Utils
import compose from "utils/compose";
import { connectRouterState } from "utils/connectRouterState";

import styles from '../message.module.scss';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShowLoader, HideLoader, GetDraft, PostMessage, GetMessageById, SweetAlert, HideAlert } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

class DraftBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			pageSize: 20,
			page: 1,
			recordCount: 0,
			messagesData: [],
			filterTitle: "",
			filterDate: null,
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Mensageria" },
				{ name: "Rascunhos", last: true },
			],
		};
		moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetDraft(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page', ''), this.state.pageSize);
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState');
		let urlState = _.get(this.props, 'urlState');
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({ ...urlState });
			this.props.GetDraft(this.stringifyFilter(urlState), urlState.page, this.state.pageSize);
		}
		if (prevProps.lsDraftsData !== this.props.lsDraftsData) {
			this.setState({ messagesData: _.orderBy(this.props.lsDraftsData.items, ['publishedAt'], ['desc']) })
			this.setState({ recordCount: this.props.lsDraftsData.count })
			this.props.HideLoader();
		}
		if (prevProps.messagesDataFailed !== this.props.messagesDataFailed) {
			// console.log('messagesDataFailed:', this.props.messagesDataFailed)
			this.props.HideLoader();
		}
	}

	handleShow = (item) => {
		this.props.SweetAlert(

			<Alert
				style={{ width: '720px', marginLeft: '-300px' }}
				title={'Visualizar dados da mensagem'}
				customClass={styles.editModal}
				onConfirm={() => this.handleEdit(item.id)}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.primary + " " + this.props.classes.button
				}
				cancelBtnCssClass={
					this.props.classes.danger + " " + this.props.classes.button + " " + styles.modalCloseButton
				}
				cancelBtnText="FECHAR"
				confirmBtnText="EDITAR"
				showCancel
			>
				<div style={{ display: 'flex' }}>
					<CustomInput
						id="title"
						name="title"
						labelText="Título"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: item.detail.title,
							disabled: true,
							type: "text",
						}}
					/>
					<CustomInput
						id="createdAt"
						name="createdAt"
						labelText="Data de criação"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: moment(item.createdAt).format("L"),
							disabled: true,
							type: "text",
						}}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<CustomInput
						labelText="Para"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							disabled: true,
							name: 'users',
							value:
								item.allUsers ? 'Todos' : item.users.length + ' Destinatários',
							type: "text",
							multiline: true,
						}}
					/>
					<CustomInput
						id="internalTitle"
						name="internalTitle"
						labelText="Descrição do lote"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							disabled: true,
							value: item.detail.internalTitle,
							type: "text",
						}}
					/>
				</div>
				<CustomInput
					id="description"
					name="description"
					labelText="Mensagem"
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						value: item.detail.description,
						disabled: true,
						type: "text",
						multiline: true,
					}}
				/>
			</Alert>
		);
	}

	handleDelete = (id) => {
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: "block" }}
				title="Apagar mensagem rascunho"
				onConfirm={() => this.handleSubmit(id)}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				cancelBtnCssClass={
					this.props.classes.button + " " + this.props.classes.warning
				}
				confirmBtnText="Sim"
				cancelBtnText="Não"
				showCancel
			>
				Você deseja mesmo <b>apagar</b> esse rascunho?
			</Alert>
		)
	}

	handleChange = (event, v) => {
		const id = event.target ? event.target.id || event.target.name : event;
		let value = '';
		if (id === "filterDate") {
			value = moment(v).format("YYYY-MM-DD");
		} else {
			value = event.target ? event.target.value : v.target.value;
		}
		this.setState({ [id]: value });
	};

	handleDeleteSuccess = () => {
		this.props.HideAlert();
		this.props.ShowLoader(<Loader />);
		this.props.GetDraft(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page'), this.state.pageSize);
	}

	handleSubmit = (id) => {
		this.props.HideAlert();
		this.props.ShowLoader(<Loader />);
		this.props.PostMessage(id).then(resp => {
			this.props.SweetAlert(
				<Alert
					success
					title="Mensagem excluída com sucesso!"
					onConfirm={() => this.handleDeleteSuccess()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.primary
					}
					showCancel={false}
				>
				</Alert>
			);
		});
	}

	handleFilter = () => {
		const { filterTitle, filterDate } = this.state;
		this.props.setURLState({ filterTitle, filterDate, page: 1 })
	}

	// PAGINATION
	handleSearch = (page) => {
		this.props.ShowLoader(<Loader />);
		this.props.setURLState({ page });
	}

	handleEdit = (id) => {
		this.props.HideAlert();
		this.props.history.push(`/admin/messages/send/edit/${id}`);
		this.props.ShowLoader(<Loader />);
	}

	handleClearFilter = () => {
		this.setState({
			filterDate: "",
			filterTitle: "",
			page: 1
		}, () => {
			this.handleFilter();
		});
	}

	stringifyFilter = (filterData) => {
		const { filterTitle, filterDate } = filterData;
		if (_.isEmpty(filterTitle) && !filterDate) {
			return '';
		}
		//
		this.props.ShowLoader(<Loader />);
		let filter = "?&$filter=";
		let fTitle = "";
		let fDate = "";

		// Título
		fTitle = filterTitle ? "substringof('" + filterTitle + "', Detail/Title) and " : "";
		// Data
		fDate += filterDate ? "day(CreatedAt) eq " + moment(filterDate).format('D') + " and month(CreatedAt) eq " + moment(filterDate).format('M') + " and year(CreatedAt) eq " + moment(filterDate).format('YYYY') + " and " : "";
		//
		filter += fTitle + fDate;
		filter = filter.substring(0, filter.lastIndexOf(" and "));
		return filter;
	}

	render() {
		const { t, classes, } = this.props;
		const { messagesData, page, pageSize, recordCount, filterTitle, filterDate } = this.state;

		return (
			<CommonPage bottomAppBar={
				<CustomPagination
					pageSize={pageSize}
					recordCount={recordCount}
					offset={pageSize * (page - 1)}
					clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
				/>}>
				<DocumentTitle title={'Connectyse - Rascunhos'} />
				<GridContainer>
					<GridItem xs={12}>
						<Heading
							textAlign="left"
							title={t("MENU_DRAFT")}
						/>
						<Breadcrumbs items={this.state.breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer style={{ marginBottom: '40px' }}>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<h5><b>Filtrar por:</b></h5>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<CustomInput
												id="filterTitle"
												name="filterTitle"
												labelText="Título"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													value: filterTitle,
													onChange: this.handleChange.bind(this, "filterTitle"),
													type: "text"
												}}
											/>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={6} md={3} lg={3}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<Datetime
												id="filterDate"
												name="filterDate"
												timeFormat={false}
												onChange={this.handleChange.bind(this, "filterDate")}
												value={filterDate ? moment(filterDate).format("L") : ''}
												renderInput={(props) => {
													return (
														<CustomInput
															labelText={"Data"}
															formControlProps={{
																fullWidth: true
															}}
															inputProps={{ ...props }}

														/>
													);
												}}
											/>
										</FormControl>
									</GridItem>
									{/* BUTTON */}
									<GridItem xs={12} sm={12} md={3} style={{ marginTop: '22px' }}>
										<Button size="sm" color="primary" onClick={this.handleFilter.bind(this)} >Filtrar</Button>
										<Button size="sm" color="whiteButtonColor" onClick={this.handleClearFilter.bind(this)}>Limpar</Button>
									</GridItem>
								</GridContainer>

								{/* TABLE */}
								<Table hover
									tableHead={["Descrição do lote", "Título", "Data", "Ações"]}
									tableData={
										messagesData.map((item, index) => ([
											item.detail.internalTitle,
											item.detail.title,
											moment(item.createdAt).format("DD/MM/YYYY"),
											<div>
												<Button
													justIcon
													size="sm"
													color="primary"
													onClick={this.handleShow.bind(this, item)}
												>
													<Search className={classes.icons} />

												</Button>
												<Button
													justIcon
													size="sm"
													color="primary"
													onClick={this.handleDelete.bind(this, item.id)}
												>
													<DeleteIcon className={classes.icon} />
												</Button>

											</div>
										]))}


									customCellClasses={[classes.left, classes.left, classes.left]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customClassesForCells={[0, 1, 2]}
									customHeadCellClasses={[classes.left, classes.left, classes.left]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customHeadClassesForCells={[0, 1, 2]}
								/>

								{/* {recordCount === 0 &&
									<GridContainer>
										<GridItem>
											Nenhum item encontrado. Mude o filtro e tente novamente.
										</GridItem>
									</GridContainer>} */}
							</CardBody>

						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}
}


DraftBox.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		lsDraftsData: state.drafts.lsDraftsData,
		//lsMessagesData: state.drafts.lsDraftsData,
		messagesDataFailed: state.messages.messagesDataFailed,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetDraft, GetMessageById, PostMessage, ShowLoader, HideLoader, SweetAlert, HideAlert }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({ filterTitle: '', filterDate: null, page: 1 }),
	withStyles(sweetAlertStyle),
	withTranslation(),
)(DraftBox);