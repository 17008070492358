import React from "react";
import NumberFormat from 'react-number-format';

export const numberFormatText = (_data, _prefix) => {
    let tSeparator = ".";
    let dSeparator = ",";
    if (localStorage.getItem("i18nextLng") != null) {
        if (localStorage.getItem("i18nextLng") === "pt-BR" || localStorage.getItem("i18nextLng") === "es") {
            tSeparator = ".";
            dSeparator = ",";
        } else {
            tSeparator = ",";
            dSeparator = ".";
        }
    }
    return <NumberFormat value={_data} displayType={'text'} thousandSeparator={tSeparator} decimalSeparator={dSeparator} prefix={_prefix} />;
}

export const iconClasses = [
    "icon-ico_romaneio_graos",
    "icon-ico_notas_fiscais",
    "icon-ico_extrato_suino",
    "icon-ico_extrato_produtor",
    "icon-ico_extrato_peixe",
    "icon-ico_extrato_leite",
    "icon-ico_extrato_frango",
    "icon-ico_imposto_renda",
    "icon-commodities",
    "icon-cadastros",
    "icon-cidade",
    "icon-ico_graos",
    "icon-centro"
];
