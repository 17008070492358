import React from 'react';
import DocumentTitle from 'react-document-title';
import { withTranslation } from 'react-i18next';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Loader from "components/Loader/Loader";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Heading from "components/Heading/Heading";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';

//Form
import FormikForm from './form/SettingsForm'
//utils
import compose from "utils/compose";
//redux
import { GetCustomizationData, ShowLoader } from 'redux/actions';

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { name: "Home" },
                { name: "Configurações", last: true },
            ],
            initialValues: {
                fullLogoUri: "",
                shortLogoUri: "",
                primaryColor: "",
                secondaryColor: ""
            }
        }

    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />)
        this.props.GetCustomizationData().then(config => {
            if (config) this.setState({ initialValues: config });
        })
    }

    render() {
        const { t } = this.props;
        const { breadcrumbItems, initialValues } = this.state;

        return (
            <>
                <GridContainer>
                    <DocumentTitle title={'Connectyse - Configurações'} />
                    <GridItem xs={12}>
                        <Heading
                            textAlign="left"
                            title={t("MENU_SETTINGS")}
                        />
                        <Breadcrumbs items={breadcrumbItems} />
                        <Card>
                            <CardBody>
                                <GridContainer justify="center" >
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <h5><b>Customização</b></h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>

                                        <FormikForm iniValues={initialValues} />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetCustomizationData, ShowLoader }
    , dispatch);

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation(),
)(Settings);