import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsDraftsData: [],
    lsUserDraftData: [],
    draftData: [],
    deleteDraft: {},
    draftsUserDataFailed: false,
    draftsDataFailed: false,
    draftsDataPost: false,
    draftsDataPostFailed: false,
};

//GET
const fetchDraftData = (state, action) => {
    return updateObject(state, {
        lsDraftsData: action.lsDraftsData
    });
};

const fetchUserDraftData = (state, action) => {
    return updateObject(state, {
        lsUserDraftData: action.lsUserDraftData
    });
};

//GET DATA FAIL
const fetchDraftDataFailed = (state, action) => {
    return updateObject(state, {
        draftsDataFailed: true
    });
};

const fetchUserDraftDataFailed = (state, action) => {
    return updateObject(state, {
        draftsUserDataFailed: true
    });
};

//POST 
const postDraftData = (state, action) => {
    return updateObject(state, {
        draftsDataPost: action.draftsDataPost
    });
};

const deleteDraftData = (state, action) => {
    return updateObject(state, {
        deleteDraft: action.deleteDraft
    });
};

//POST FAIL 
const postDraftDataFailed = (state, action) => {
    return updateObject(state, {
        draftsDataPostFailed: true
    });
};

const fetchDraftById = (state, action) => {
    return updateObject(state, {
        draftData: action.draftData
    });
};




//
const draftsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_USER_DRAFT_DATA:
            return fetchUserDraftData(state, action);

        case actionTypes.FETCH_DRAFT_DATA:
            return fetchDraftData(state, action);

        case actionTypes.POST_DRAFT_DATA:
            return postDraftData(state, action);

        case actionTypes.DELETE_DRAFT_DATA:
            return deleteDraftData(state, action);

        case actionTypes.POST_DRAFT_DATA_FAILED:
            return postDraftDataFailed(state, action);

        case actionTypes.FETCH_DRAFT_FAILED:
            return fetchDraftDataFailed(state, action);

        case actionTypes.FETCH_USER_DRAFT_FAILED:
            return fetchUserDraftDataFailed(state, action);

        case actionTypes.FETCH_DRAFT_DATA_BY_ID:
            return fetchDraftById(state, action);

        default:
            return state;
    }
};

export default draftsReducer;