import React from "react";
import AsyncSelect from 'react-select/async';
import { NavLink } from "react-router-dom";
import * as _ from "lodash";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from "yup";
import Alert from "react-bootstrap-sweetalert";
import * as moment from 'moment';

// @material-ui/core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Icon from '@material-ui/core/Icon';

// core components
import CustomTooltip from 'components/CustomTooltip/CustomTooltip.jsx'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FileInput from "components/FileInput/FileInput.jsx";
import FieldInput from "components/FieldForm/FieldInput.jsx";
import styles from '../message.module.scss';

import modelCsv from "../../../assets/img/modelCsv.png"

import { Formik, Form, Field } from 'formik';

import { GetUsers, MessageFormData, GetDraftUsersByUserName } from 'redux/actions';

import compose from "utils/compose";

/**
 *   Form using formik to send messages to users at mobile app.
 * 
 */

const colourStyles = {
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#9CBD5E",
            color: "white",
            borderRadius: '15px'
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: "white"
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: "white",
        borderRadius: '15px',
        ":hover": {
            backgroundColor: "#85a24f",
            color: "white",
            cursor: 'pointer'
        }
    })
};

const FieldTextBox = ({ field, label, form, classes, ...props }) => (
    <>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
            <FormLabel
                className={classes.labelHorizontal}
                error={form.touched.description && Boolean(form.errors.description)}
            >
                {label}:
                </FormLabel>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
            <FormControl
                fullWidth
            >
                <CustomInput
                    helpText={
                        field.value.length <= 200 ? (200 - field.value.length) + " - dígitos restantes." : "Sua mensagem ultrapassou o número máximo de caracteres"
                    }
                    error={form.touched.description && Boolean(form.errors.description)}
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        ...field,
                        multiline: true,
                        rows: '3',
                        placeholder: 'Digite a mensagem a ser enviada ao usuário do app.',
                    }}
                />
            </FormControl>
        </GridItem>
    </>
);

const FieldSelect = ({ field, form, label, classes, loadOptions, ...props }) => (
    <>
        <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ marginTop: '10px' }}>
            <FormLabel className={classes.labelHorizontal}
                error={form.touched.users && Boolean(form.errors.users)}
            >
                {label}:
                </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={8} md={7} lg={6} xl={6} >
            <FormControl
                className={
                    classes.labelHorizontal
                }
                fullWidth
                error={form.touched.users && Boolean(form.errors.users)}
            >
                <AsyncSelect
                    error={form.touched.description && Boolean(form.errors.description)}
                    name={field.name}
                    isDisabled={form.values.allUsers ? true : false}
                    styles={colourStyles}
                    value={field.value}
                    cacheOptions
                    defaultOptions
                    placeholder={form.values.allUsers ? "Todos os usuários da lista" : "Digite o nome do usuário"}
                    loadingMessage={() => "Procurando..."}
                    noOptionsMessage={() => "Nenhum resultado encontrado"}
                    loadOptions={loadOptions}
                    onChange={(e, event) => {
                        form.setFieldValue(event.name, e)
                        form.setFieldTouched(event.name, true, false);
                        form.handleChange(e);
                    }}
                    isMulti
                />


            </FormControl>
        </GridItem>
    </>
);


const FieldCheckbox = ({ classes, field, form, sweetAlert, hideAlert, ...props }) => (
    <GridItem xs={2} sm={2} md={2} lg={2} >
        <FormControlLabel className={classes.labelHorizontal}
            control={
                <Checkbox
                    checked={field.value}
                    name={field.name}
                    onChange={(e, event) => {
                        if (event) {
                            handleMessageCheckAllAlert(form.setFieldValue, sweetAlert, hideAlert, classes);
                        } else {
                            form.setFieldValue(field.name, event)
                        }
                        form.setFieldTouched(event.name, true, false);
                        form.handleChange(e);
                    }}
                    color="primary"
                />
            }
            label="Todos"
        />
    </GridItem>
);

// const FieldButtons = ({ classes, values, setFieldValue, handleSave, ...props }) => (
//     <>
//         <GridContainer>
//             <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
//                 <Button
//                     size="sm"
//                     color="primary"
//                     onClick={(e) => {
//                         setFieldValue('isDraft', false)
//                         handleSave(values);
//                     }
//                     }
//                 >
//                     Enviar
//             </Button>
//                 <Button
//                     size="sm"
//                     color="greenBorderButtonColor"
//                     onClick={(e) => {
//                         setFieldValue('isDraft', true)
//                         handleSave(values);
//                     }
//                     }
//                 >
//                     Salvar rascunho
//             </Button>
//                 <NavLink to="/admin/messages/draft">
//                     <Button size="sm" color="whiteButtonColor">Visualizar rascunhos</Button>
//                 </NavLink>
//             </GridItem>
//         </GridContainer>
//     </>
// );

const handleMessageCheckAllAlert = (setFieldValue, sweetAlert, hideAlert, classes) => {
    sweetAlert(
        <Alert
            warning
            style={{ display: "block", fontSize: '8px' }}
            title={"Gostaria de enviar a mensagem para todos da sua lista?"}
            showCancel={true}
            closeOnClickOutside={false}
            onConfirm={() => {
                setFieldValue('allUsers', true);
                setFieldValue('users', []);
                hideAlert();
            }}
            onCancel={() => {
                setFieldValue('allUsers', false);
                hideAlert();
            }}
            confirmBtnCssClass={
                classes.button + " " + classes.primary
            }
            cancelBtnCssClass={
                classes.button + " " + classes.danger
            }
            confirmBtnText={"SIM"}
            cancelBtnText={"NÃO"}
        >
            <div style={{ fontSize: '14px' }}>
                Com esse item selecionado a sua mensagem será
                <br></br>
                enviada para todos da sua lista de contatos.
                </div>
        </Alert>
    );
}

class MessageForm extends React.Component {

    promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.handleUsersList(inputValue));
            }, 1000);
        });

    handleUsersList = (value) => {
        var inputValue = value.trim().toLowerCase();
        inputValue = "?$filter=substringof('" + inputValue + "', FirstName) ";

        return this.props.GetUsers(inputValue, "", 1)
            .then(() => {
                return (this.props.lsUsers.items.map((user) => {
                    return {
                        value: user.userName,
                        label: user.userName + " - " + user.firstName,
                        id: user.id,
                        active: user.active,
                        userName: user.userName,
                        fullName: user.firstName,
                    }
                }));
            });
    }


    handleSave = (values) => {
        let obj = this.handleFormToObj(values);
        this.props.MessageFormData({ message: obj, isSubmitted: true, isDraft: values.isDraft });
    }

    handleFormToObj = (values) => {
        let obj =
        {
            id: values.id,
            users: values.users,
            detail: {
                internalTitle: values.internalTitle,
                title: values.title,
                description: values.description,
                publishedAt: new moment(),
                visible: !values.isDraft,
            },
            sented: false,
            allUsers: values.allUsers,
            deleted: false,
            isDraft: values.isDraft
        }
        return obj;
    }

    callbackFromChild = async (cpfs, setFieldValue) => {
        if (cpfs) {
            let users = cpfs.filter(x => (x.isValid === true));
            if (users.length > 0) {
                let arrCpfs = _.map(users, 'cpf');

                const resp = await this.props.GetDraftUsersByUserName(arrCpfs);

                let trulyUsers = [];

                if (resp.length > 0) {
                    resp.map((user) => {
                        let obj = {
                            active: user.active,
                            fullName: user.fullName,
                            id: user.id,
                            label: user.userName + " - " + user.fullName,
                            userName: user.userName,
                        }
                        return trulyUsers.push(obj);
                    });
                }

                if (trulyUsers.length > 0) {
                    trulyUsers.map(x => {
                        let objIndex = cpfs.findIndex((obj => obj.cpf === x.userName));
                        return cpfs[objIndex].active = true;
                    })
                    let invalids = cpfs.filter(x => !x.isValid).length;
                    let usersNotFound = cpfs.filter(x => (!x.active && x.isValid)).length;
                    let usersFound = cpfs.filter(x => x.active).length;

                    if (usersFound > 0) {
                        this.props.SweetAlert(
                            <Alert
                                title={"Deseja enviar para os CPFs cadastrados listados abaixo?"}
                                showCancel={true}
                                closeOnClickOutside={false}
                                onConfirm={() => {
                                    setFieldValue('users', trulyUsers);
                                    this.props.HideAlert();
                                }}
                                onCancel={() => this.props.HideAlert()}
                                confirmBtnCssClass={
                                    this.props.classes.button + " " + this.props.classes.primary
                                }
                                cancelBtnCssClass={
                                    this.props.classes.button + " " + this.props.classes.danger
                                }
                                confirmBtnText={"SIM"}
                                cancelBtnText={"NÃO"}
                                customClass={styles.modalClass}
                            >
                                <div style={{ fontSize: '14px', flexGrow: 1, overflow: 'auto', height: '250px', marginBottom: '10px' }}>
                                    {
                                        cpfs.map((x, index) => (
                                            <div key={index} className={styles.userItem}>
                                                <div style={{ paddingTop: '5px' }}>
                                                    <span>{x.cpf}</span>
                                                </div>
                                                <div>
                                                    <Icon style={{ paddingTop: '2px', float: 'right', color: x.active ? 'green' : x.isValid ? 'default' : 'red' }}>{x.active ? 'done' : 'close'}</Icon>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '12px', height: '40px' }}>
                                    <div><b>Cadastrado(s):</b> {usersFound} <Icon style={{ color: 'green', verticalAlign: 'middle' }}>done</Icon></div>
                                    <div ><b>Não cadastrado(s):</b> {usersNotFound} <Icon style={{ verticalAlign: 'middle' }}>close</Icon></div>
                                    <div><b>Inválido(s):</b> {invalids} <Icon style={{ color: 'red', verticalAlign: 'middle' }}>close</Icon></div>
                                    <div style={{ float: 'left' }}><b>Total:</b> {cpfs.length}</div>
                                </div>
                                {invalids > 0 &&
                                    <div>
                                        <span style={{ color: 'red', fontSize: '12px', marginBottom: '10px' }}>O arquivo <b>csv</b> está com CPF(s) inválido(s),
                                            certifique-se que esteja anexando o arquivo no formato correto.
                                            {/* caso haja dúvidas <a>clique aqui</a> para visualizar o modelo. */}
                                        </span>
                                    </div>
                                }
                            </Alert >
                        );
                    } else {
                        this.props.SweetAlert(
                            <Alert
                                warning
                                style={{ display: "block" }}
                                title={"Ocorreu um erro na leitura do arquivo"}
                                onConfirm={() => this.props.HideAlert()}
                                confirmBtnCssClass={
                                    this.props.classes.button + " " + this.props.classes.danger
                                }
                            >
                                Não foram encontrados usuários cadastrados no sistema.
                            </Alert>
                        );
                    }
                }
            } else {
                this.props.SweetAlert(
                    <Alert
                        warning
                        style={{ display: "block" }}
                        title={"Ocorreu um erro na leitura do arquivo"}
                        onConfirm={() => this.props.HideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.danger
                        }
                    >
                        Nenhum CPF válido foi encontrado.
                    </Alert>
                );
            }

        } else {
            this.props.SweetAlert(
                <Alert
                    warning
                    style={{ display: "block" }}
                    title={"Ocorreu um erro na leitura do arquivo"}
                    onConfirm={() => this.props.HideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    Verifique se o arquivo está no formato esperado e tente novamente.
                </Alert>
            );
        }
        this.props.HideLoader();
    }

    render() {
        const { iniValues } = this.props;

        return (
            <Formik
                initialValues={iniValues}
                validationSchema={
                    Yup.object({
                        internalTitle: Yup.string().required('* Campo obrigatório'),
                        title: Yup.string().required('* Campo obrigatório'),
                        description: Yup.string().required('* Campo obrigatório').max(200),
                        allUsers: Yup.boolean(),
                        users: Yup.array()
                            .when("allUsers", {
                                is: false,
                                then: Yup.array().required("Você deve selecionar ao menos um destinatário")
                            })
                    })
                }
                onSubmit={
                    (values, { setSubmitting }) => {
                        this.handleSave(values)
                    }
                }
                enableReinitialize
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <Form>
                        <GridContainer>
                            <Field name='internalTitle'>
                                {({ field, form }) => (
                                    <FieldInput touched={form.touched.internalTitle} error={form.errors.internalTitle} form={form} field={field} label={'Descrição do lote'} classes={this.props.classes} labelGrid={2} />
                                )}
                            </Field>
                            <Field name='users'>
                                {({ field, form }) => (
                                    <FieldSelect form={form} field={field} label={'Para'} classes={this.props.classes} loadOptions={this.promiseOptions} />
                                )}
                            </Field>
                            <Field name='allUsers'>
                                {({ field, form }) => (
                                    <FieldCheckbox field={field} form={form} classes={this.props.classes} sweetAlert={this.props.SweetAlert} hideAlert={this.props.HideAlert} />
                                )}
                            </Field>
                            <Field name='fileUsers'>
                                {({ field, form }) => (
                                    <GridItem xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <FormControl
                                            className={
                                                this.props.classes.labelHorizontal
                                            }
                                            fullWidth
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <FileInput name={field.name} callFromParent={this.callbackFromChild} classes={this.props.classes} setFieldValue={setFieldValue} />
                                                <CustomTooltip
                                                    content={
                                                        <>
                                                            O formato a ser usado no arquivo de importação é CSV e o layout deve ser conforme indicado na imagem abaixo. Uma coluna, com titulo (CPF), sendo um por linha, do tipo Texto.
                                                            <img src={modelCsv} style={{ marginTop: '10px' }} alt="Modelo arquivo" />
                                                        </>
                                                    }
                                                />
                                            </div>

                                        </FormControl>
                                    </GridItem>
                                )}
                            </Field>
                            <Field name='title'>
                                {({ field, form }) => (
                                    <FieldInput touched={form.touched.title} error={form.errors.title} field={field} form={form} label={'Título'} classes={this.props.classes} labelGrid={2} />

                                )}
                            </Field>
                            <Field name='description'>
                                {({ field, form }) => (
                                    <FieldTextBox form={form} field={field} label={'Mensagem'} classes={this.props.classes} />
                                )}
                            </Field>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                <Button
                                    size="sm"
                                    color="primary"
                                    onClick={
                                        () => {
                                            setFieldValue('isDraft', false, false)
                                            handleSubmit()
                                        }
                                    }
                                >
                                    Enviar
                                </Button>
                                <Button
                                    size="sm"
                                    color="greenBorderButtonColor"
                                    onClick={() => {
                                        setFieldValue('isDraft', true, false)
                                        handleSubmit()
                                    }
                                    }
                                >
                                    Salvar rascunho
                                 </Button>
                                <NavLink to="/admin/messages/draft">
                                    <Button size="sm" color="whiteButtonColor">Visualizar rascunhos</Button>
                                </NavLink>
                            </GridItem>
                        </GridContainer>
                        {/* <FieldButtons setFieldValue={setFieldValue} values={values} handleSave={handleSubmit} /> */}
                    </Form>
                )}
            </Formik>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetUsers, MessageFormData, GetDraftUsersByUserName }, dispatch);

export default compose(
    connect(null, mapDispatchToProps)
)(MessageForm);
