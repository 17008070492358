import React from "react";
import { connect } from 'react-redux';

import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Container } from "@material-ui/core";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import { dashRoutes } from "./routes.js";

// Utils
import compose from "utils/compose";

import { withTranslation } from 'react-i18next';

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/bg_menu.jpg";
import logoF from "assets/img/logo_menu_cofco.png";
import logoP from "assets/img/simbolo_menu_cofco.png";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const AdminContext = React.createContext({ mobileOpen: false, sidebarMini: false });

const customization = JSON.parse(localStorage.getItem("config"));

class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false,
			image: image,
			color: "customizeColor",
			bgColor: "black",
			hasImage: true,
			logo: isWidthDown('md', this.props.width) ? (props.config ? props.config.shortLogoUri : logoP) : (props.config ? props.config.fullLogoUri : logoF),
			fixedClasses: "dropdown",
			miniActive: isWidthDown('md', this.props.width)
		};
		this.resizeFunction = this.resizeFunction.bind(this);
	}

	componentDidMount() {
		console.log(this.props)
		window.addEventListener("resize", this.resizeFunction);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeFunction);
	}
	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			// this.refs.mainPanel.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}
	}
	handleImageClick = image => {
		this.setState({ image: image });
	};
	handleColorClick = color => {
		this.setState({ color: color });
	};
	handleBgColorClick = bgColor => {
		this.setState({ bgColor: bgColor });
	};
	handleFixedClick = () => {
		if (this.state.fixedClasses === "dropdown") {
			this.setState({ fixedClasses: "dropdown show" });
		} else {
			this.setState({ fixedClasses: "dropdown" });
		}
	};
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};
	getRoute() {
		return this.props.location.pathname !== "/admin/full-screen-maps";
	}
	getActiveRoute = dashRoutes => {
		const { t } = this.props;

		let activeRoute = "";
		for (let i = 0; i < dashRoutes.length; i++) {
			if (dashRoutes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(dashRoutes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(dashRoutes[i].layout + dashRoutes[i].path) !== -1
				) {
					return t(dashRoutes[i].name);
				}
			}
		}
		return activeRoute;
	};
	getRoutes = dashRoutes => {
		return dashRoutes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	sidebarMinimize = (miniActive) => {
		this.setState({ miniActive, logo: miniActive ? (this.props.config ? this.props.config.shortLogoUri : logoP) : (this.props.config ? this.props.config.fullLogoUri : logoF) });
	}
	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}
	render() {
		const { classes, user, users, alert, loading, ...rest } = this.props;
		const mainPanel =
			cx({
				[classes.mainPanel]: true,
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
		return (

			<AdminContext.Provider value={{ mobileOpen: this.state.mobileOpen, sidebarMini: this.state.miniActive }}>
				<div className={classes.wrapper}>
					{alert}
					{loading}
					<AdminNavbar
						miniActive={this.state.miniActive}
						sidebarMinimize={this.sidebarMinimize}
						brandText={"Connectyse"}
						handleDrawerToggle={this.handleDrawerToggle}
						user={user}
					/>
					<Sidebar
						sidebarMinimize={this.sidebarMinimize}
						routes={dashRoutes}
						logo={this.state.logo}
						image={this.state.image}
						handleDrawerToggle={this.handleDrawerToggle}
						open={this.state.mobileOpen}
						color={this.state.color}
						bgColor={this.state.bgColor}
						miniActive={this.state.miniActive && !this.state.mobileOpen}
						mobileOpen={this.state.mobileOpen}
						user={user}
						{...rest}
					/>
					<div className={mainPanel}>
						<div className={classes.toolbar} />
						<Container fixed={false} maxWidth={'xl'}>

							{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
							{this.getRoute() ? (
								<Switch>{this.getRoutes(dashRoutes)}</Switch>
							) : (
									<div className={classes.map}>
										<Switch>{this.getRoutes(dashRoutes)}</Switch>
									</div>
								)}
							<div style={{ display: 'block', zIndex: '5', float: 'right', color: '#666', margin: '0 20px 10px', fontSize: '0.7rem', opacity: '0.6' }}>
								<span>
									{"Release " + `${process.env.REACT_APP_CONNECTYSE_BUILD_VERSION}`}
								</span>
							</div>
						</Container>
					</div>
				</div>
			</AdminContext.Provider>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const { users, authentication, sweetAlert, loader, config } = state;
	const { user } = authentication;
	return {
		user,
		users,
		alert: sweetAlert.alert,
		config: config.customConfigData,
		loading: loader.loading
	};
}
export { AdminContext };

export default compose(
	connect(mapStateToProps),
	withStyles(appStyle),
	withWidth(),
	withTranslation()
)(Dashboard);