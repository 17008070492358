// LOGIN USER
export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const LOGOUT = "USERS_LOGOUT";
export const GETALL_REQUEST = "USERS_GETALL_REQUEST";
export const GETALL_SUCCESS = "USERS_GETALL_SUCCESS";
export const GETALL_FAILURE = "USERS_GETALL_FAILURE";

// DASHBOARD
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_CHARTS_DATA = "FETCH_CHARTS_DATA";
export const FETCH_ANNOUNCEMENT_DATA = "FETCH_ANNOUNCEMENT_DATA";
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
export const FETCH_CHARTS_FAILED = "FETCH_CHARTS_FAILED";
export const FETCH_ANNOUNCEMENT_FAILED = "FETCH_ANNOUNCEMENT_FAILED";

// APP DATA TYPE
export const FETCH_APP_DATA_TYPE_DATA = "FETCH_APP_DATA_TYPE_DATA";
export const FETCH_APP_DATA_TYPE_FAILED = "FETCH_APP_DATA_TYPE_FAILED";
export const POST_APP_DATA_TYPE_DATA = "POST_APP_DATA_TYPE_DATA";
// MANAGEMENT - GENERAL RULES
export const FETCH_GENERAL_RULES_DATA = "FETCH_GENERAL_RULES_DATA";
export const UPDATE_GENERAL_RULES = "UPDATE_GENERAL_RULES";
export const FETCH_GENERAL_RULES_LOGS = "FETCH_GENERAL_RULES_LOGS";
export const FETCH_GENERAL_RULES_FAILED = "FETCH_GENERAL_RULES_FAILED";
export const FETCH_GENERAL_RULES_LOG_FAILED = "FETCH_GENERAL_RULES_LOG_FAILED";

// MANAGEMENT - REGISTRATION TYPES
export const FETCH_REGISTRATION_TYPES_DATA = "FETCH_REGISTRATION_TYPES_DATA";
export const UPDATE_REGISTRATION_TYPES_DATA = "UPDATE_REGISTRATION_TYPES_DATA";
export const FETCH_REGISTRATION_TYPES_FAILED = "FETCH_REGISTRATION_TYPES_FAILED";

// IMAGES
export const POST_IMAGE_DATA = "POST_IMAGE_DATA";
export const SET_FILE_DATA = "SET_FILE_DATA";
export const FETCH_THUMBNAILS = "FETCH_THUMBNAILS";
export const FETCH_IMAGE_FAILED = "FETCH_IMAGE_FAILED";

// SWEET-ALERT
export const SWEET_ALERT = "SWEET_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// LOADER
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//MESSAGES
export const FETCH_MESSAGE_DATA = "FETCH_MESSAGE_DATA";
export const POST_MESSAGE_DATA = "POST_MESSAGE_DATA";
export const POST_MESSAGE_DATA_FAIL = "POST_MESSAGE_DATA_FAIL";
export const FETCH_MESSAGE_FAILED = "FETCH_MESSAGE_FAILED";
export const FETCH_MESSAGE_DATA_BY_ID = "FETCH_MESSAGE_DATA_BY_ID";
export const DELETE_MESSAGE_DATA = "DELETE_MESSAGE_DATA";
export const MESSAGE_FORM_DATA = "MESSAGE_FORM_DATA";

//DRAFT
export const FETCH_DRAFT_DATA = "FETCH_DRAFT_DATA";
export const POST_DRAFT_DATA = "POST_DRAFT_DATA";
export const FETCH_USER_DRAFT_DATA = "FETCH_USER_DRAFT_DATA";
export const FETCH_DRAFT_DATA_BY_ID = "FETCH_DRAFT_DATA_BY_ID";
export const POST_DRAFT_DATA_FAILED = "POST_DRAFT_DATA_FAILED";
export const FETCH_DRAFT_FAILED = "FETCH_DRAFT_FAILED";
export const FETCH_USER_DRAFT_FAILED = "FETCH_USER_DRAFT_FAILED";
export const DELETE_DRAFT_DATA = "DELETE_DRAFT_DATA";

// LOCATION
export const FETCH_CITY_DATA = "FETCH_CITY_DATA";
export const FETCH_STATE_DATA = "FETCH_STATE_DATA";
export const FETCH_COUNTRIES_DATA = "FETCH_COUNTRIES_DATA";
export const FETCH_CITY_FAILED = "FETCH_CITY_DATA_FAILED";
export const FETCH_STATE_FAILED = "FETCH_STATE_FAILED";
export const FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED";

//
export const FETCH_UNAUTH_USER = "FETCH_UNAUTH_USER";

export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export const FETCH_CHANGE_PASSWORD_DATA = "FETCH_CHANGE_PASSWORD_DATA";
export const FETCH_CHANGE_PASSWORD_FAILED = "FETCH_CHANGE_PASSWORD_FAILED";

// SYSTEM CONFIG
export const FETCH_CUSTOMIZATION_DATA = "FETCH_CUSTOMIZATION_DATA";
export const FETCH_CUSTOMIZATION_FAILED = "FETCH_CUSTOMIZATION_FAILED";
export const POST_CUSTOMIZATION_DATA = "POST_CUSTOMIZATION_DATA";
export const POST_CUSTOMIZATION_FAILED = "POST_CUSTOMIZATION_FAILED";

//INTEGRATION
export const FETCH_INTEGRATION_DATA = "FETCH_INTEGRATION_DATA";
export const FETCH_INTEGRATION_FAILED = "FETCH_INTEGRATION_FAILED";
export const FETCH_INTEGRATION_BLOB_DATA = "FETCH_INTEGRATION_BLOB_DATA";
export const FETCH_INTEGRATION_BLOB_FAILED = "FETCH_INTEGRATION_BLOB_FAILED";
export const FETCH_INTEGRATION_BY_ID_DATA = "FETCH_INTEGRATION_BY_ID_DATA";
export const FETCH_INTEGRATION_BY_ID_FAILED = "FETCH_INTEGRATION_BY_ID_FAILED";

export const FETCH_DATA_TYPES = "FETCH_DATA_TYPES";
export const FETCH_DATA_TYPES_FAILED = "FETCH_DATA_TYPES_FAILED";
export const UPDATE_DATA_TYPE_DATA = "UPDATE_DATA_TYPE_DATA";
export const UPDATE_DATA_TYPE_FAILED = "UPDATE_DATA_TYPE_FAILED";
export const FETCH_DATA_TYPE_BY_ID = "FETCH_DATA_TYPE_BY_ID";
export const FETCH_BUSINESS_PARTNER_DATA_TYPE = "FETCH_BUSINESS_PARTNER_DATA_TYPE";
export const FETCH_BUSINESS_PARTNER_DATA_TYPE_FAILED = "FETCH_BUSINESS_PARTNER_DATA_TYPE_FAILED";
// GetDataTypes