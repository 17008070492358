import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';

import { Route, Switch, Redirect } from 'react-router-dom'
import * as serviceWorker from './utils/serviceWorker';
import AuthLayout from "pages/Auth";
import AdminLayout from "pages/Admin";
//import RegisterLayout from "pages/Register";
import { PrivateRoute } from "components/PrivateRoute.jsx";
import { Provider } from 'react-redux';
import { history } from './utils';
import { ConnectedRouter } from 'connected-react-router';
import store from './redux/store';
import axios from 'axios';

import { customColors, successColor } from "assets/jss/material-dashboard-pro-react.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import 'utils/i18n';
import 'assets/icons/style.css';
import { CssBaseline, createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ShellProvider } from 'utils/shell';

// import NetworkService from './utils/network-service'; 
// NetworkService.setupInterceptors(store);

//configureFakeBackend();
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

axios.defaults.headers.common['x-apikey'] = "2cdc569b-dbd7-4813-8f41-bd82f6b1bd81";

axios.defaults.baseURL = process.env.REACT_APP_CONNECTYSE_API_URL;

/******************
 * MOMENT CONFIG
 ******************/
// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
if (localStorage.getItem("i18nextLng") != null) {
	Moment.globalLocale = localStorage.getItem('i18nextLng').toLowerCase();
} else {
	Moment.globalLocale = "pt-BR";
}

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MM YYYY';

// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo';

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span';

// Upper case all rendered dates.
Moment.globalFilter = (d) => {
	return d.toUpperCase();
};

/* It needs to find a better way as soon as possible */

window.onbeforeunload = function () {
	if (localStorage.getItem("i18next_pt-BR-common") != null) {
		localStorage.removeItem('i18next_pt-BR-common');
	}
	if (localStorage.getItem("i18next_es-common") != null) {
		localStorage.removeItem('i18next_es-common');
	}
	if (localStorage.getItem("i18next_en-common") != null) {
		localStorage.removeItem('i18next_en-common');
	}
}


const theme = createMuiTheme({
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: customColors[0],
			// dark: will be calculated from palette.primary.main,
			contrastText: '#fff'
		},
		secondary: {
			// light: will be calculated from palette.primary.main,
			main: successColor[0],
			// dark: will be calculated from palette.primary.main,
			contrastText: '#fff'
		},
	}
});

const App = () =>
	<React.Fragment>
		<CssBaseline />
		<ShellProvider>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<Suspense fallback={<div />}>
						<ConnectedRouter history={history}>
							<Switch>
								<Route path="/auth" component={AuthLayout} />
								<PrivateRoute path="/admin" component={AdminLayout} />
								<Redirect from="/" to="/admin/dashboard" />
							</Switch>
						</ConnectedRouter>
					</Suspense>
				</ThemeProvider>
			</Provider>
		</ShellProvider>
	</React.Fragment>

ReactDOM.render(
	<App />, document.getElementById('root'));

//#region serviceWorker

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

//#endregion
