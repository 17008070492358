import React from 'react';

import * as _ from "lodash";

// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/styles';

// core components
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const FieldInput = ({ field, label, touched, error, form, labelGrid, ...props }) => {
    const inputGrid = (12 - labelGrid);
    return (
        <>
            <GridItem xs={12} sm={labelGrid} md={labelGrid} lg={labelGrid} xl={labelGrid} >
                <FormLabel
                    className={props.classes.labelHorizontal}
                    error={(touched && Boolean(error))}
                >
                    {label}:
                </FormLabel>
            </GridItem>

            <GridItem xs={12} sm={inputGrid} md={inputGrid} lg={inputGrid} xl={inputGrid} >
                <FormControl
                    fullWidth
                >
                    <CustomInput
                        error={(touched && Boolean(error))}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            ...field,
                        }}
                    />
                </FormControl>
            </GridItem>
        </>
    )
};
export default withStyles(styles)(FieldInput);
