import axios from 'axios';

// GET
export const GetIntegrationData = (_filter, _page, _limitPage) => {
    let page = '';
    let count = (_page - 1) * _limitPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_limitPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_limitPage}`
    } 
    return axios.get(`/core/api/Jobs/GetAll${_filter}${page}`).then(response => response.data);
}

export const GetIntegrationById = (id) => {
    let filter = `?$filter=Id eq '${id}'`
    return axios.get(`/core/api/Jobs/GetAll${filter}`).then(response => response.data.items[0]);
}

export const GetBlobFileById = (id) => {
    return axios.get(`/core/api/Jobs/GetBlobFileContent`, { params: { blobFileId: id }}).then(response => response.data);
}

export const GetDataTypes = () =>
    axios.get(`/core/api/Types/GetAll`).then(response => response.data);

export const FilterIntegration = () =>
    axios.get(`/core/api/Data/Jobs`).then(response => response.data);