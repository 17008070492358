import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import Alert from "react-bootstrap-sweetalert";
import DocumentTitle from 'react-document-title';
import Datetime from "react-datetime";
import MaterialTable, { MTableCell, MTableToolbar } from 'material-table';

// @material-ui/core components
import { FormControl, withStyles, Paper } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import DeleteIcon from '@material-ui/icons/Delete';
import Drafts from '@material-ui/icons/Drafts';

// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Loader from "components/Loader/Loader.jsx";
import CommonPage from "../../../components/Pages/CommonPage";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import Heading from "components/Heading/Heading";
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx';

// Utils
import compose from "utils/compose";
import { connectRouterState } from "utils/connectRouterState";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import styles from '../message.module.scss';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetMessages, GetMessageById, DeleteMessage, ShowLoader, HideLoader, SweetAlert, HideAlert } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

class StatusMessages extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dataTypeTable: [],
			messagesData: [],
			alert: null,
			pageSize: 20,
			page: 1,
			recordCount: 0,
			filterTitle: '',
			filterDate: null,
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Mensageria" },
				{ name: "Status dos envios", last: true },
			],
			inputDescription: "",
		};

		//this.hideAlert = this.hideAlert.bind(this);
		moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
	}
	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetMessages(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page'), this.state.pageSize);
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState');
		let urlState = _.get(this.props, 'urlState');
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({ ...urlState });
			this.props.GetMessages(this.stringifyFilter(urlState), urlState.page, this.state.pageSize);
		}
		if (prevProps.lsMessagesData !== this.props.lsMessagesData) {
			//console.log('messagesData:', this.props.messagesData)
			this.setState({ messagesData: _.orderBy(this.props.lsMessagesData.items, ['publishedAt'], ['desc']) })
			this.setState({ recordCount: this.props.lsMessagesData.count })

			this.props.HideLoader();
		}
		if (prevProps.messagesDataFailed !== this.props.messagesDataFailed) {
			console.log('messagesDataFailed:', this.props.messagesDataFailed)
			this.props.HideLoader();
		}
	}

	createColumnInfo = (obj, index) => {
		switch (index) {
			case 0:
				return <div style={{ display: "flex" }} >
					<div>
						<span>
							{obj.detail.title}
						</span>
						<br />
						<span style={{ fontSize: '12px', fontStyle: 'italic' }}>
							{obj.detail.internalTitle}
						</span>
					</div>
				</div>
			case 2:
				return <div>
					<span>{moment(obj.detail.publishedAt).format("DD/MM/YYYY")}</span>
					<br />
					<small>{moment(obj.detail.publishedAt).format('HH:mm')}</small>
				</div>
			default:
				return "";
		}
	}

	handleShow = (item) => {
		this.props.SweetAlert(

			<Alert
				style={{ width: '720px', marginLeft: '-300px' }}
				customClass={styles.editModal}
				title="Visualizar mensagem"
				onConfirm={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.danger + " " + this.props.classes.button
				}
				confirmBtnText="Fechar"
				showCancel={false}
			>
				<div style={{ display: 'flex' }}>
					<CustomInput
						id="title"
						name="title"
						labelText="Título"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: item.detail.title,
							disabled: true,
							type: "text",
						}}
					/>
					<CustomInput
						id="createdAt"
						name="createdAt"
						labelText="Data de criação"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: moment(item.createdAt).format("L"),
							disabled: true,
							type: "text",
						}}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<CustomInput
						labelText="Para"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							disabled: true,
							name: 'users',
							value:
								item.allUsers ? 'Todos' : item.users.length + ' Destinatários',
							type: "text",
							multiline: true,
						}}
					/>
					<CustomInput
						id="internalTitle"
						name="internalTitle"
						labelText="Descrição do lote"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							disabled: true,
							value: item.detail.internalTitle,
							type: "text",
						}}
					/>
				</div>
				<CustomInput
					id="description"
					name="description"
					labelText="Mensagem"
					formControlProps={{
						fullWidth: true
					}}
					inputProps={{
						value: item.detail.description,
						disabled: true,
						type: "text",
						multiline: true,
					}}
				/>
			</Alert>
		);
	}

	handleDelete = (id) => {
		this.props.SweetAlert(
			<Alert
				warning
				title="Apagar mensagem"
				onConfirm={() => this.handleSubmit(id)}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.button + " " + this.props.classes.primary
				}
				cancelBtnCssClass={
					this.props.classes.button + " " + this.props.classes.warning
				}
				confirmBtnText="Sim"
				cancelBtnText="Não"
				showCancel
			>
				Você deseja mesmo <b>apagar</b> essa mensagem?
			</Alert>
		)
	}

	handleDeleteSuccess = () => {
		this.props.HideAlert();
		this.props.ShowLoader(<Loader />);
		this.props.GetMessages(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page'), this.state.pageSize);
	}

	handleSubmit = (id) => {
		this.props.HideAlert();
		this.props.ShowLoader();
		this.props.DeleteMessage(id).then(resp => {
			this.props.SweetAlert(
				<Alert
					success
					title="Mensagem excluída com sucesso!"
					onConfirm={() => this.handleDeleteSuccess()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.primary
					}
					showCancel={false}
				>
				</Alert>
			);
		});
	}

	handleChange = (event, v) => {
		const id = event.target ? event.target.id || event.target.name : event;
		let value = '';
		if (id === "filterDate") {
			value = moment(v).format("YYYY-MM-DD");
		} else {
			value = event.target ? event.target.value : v.target.value;
		}
		this.setState({ [id]: value });
	};

	handleFilter = () => {
		const { filterTitle, filterDate } = this.state;
		this.props.setURLState({ filterTitle, filterDate, page: 1 })
	}

	stringifyFilter = (filterData) => {
		const { filterTitle, filterDate } = filterData;
		if (_.isEmpty(filterTitle) && !filterDate) {
			return '';
		}
		//
		this.props.ShowLoader(<Loader />);
		let filter = "?&$filter=";
		let fTitle = "";
		let fDate = "";

		// Título
		fTitle = filterTitle ? "substringof('" + filterTitle + "', Detail/Title) and " : "";
		// Data
		fDate += filterDate ? "day(Detail/PublishedAt) eq " + moment(filterDate).format('D') + " and month(Detail/PublishedAt) eq " + moment(filterDate).format('M') + " and year(Detail/PublishedAt) eq " + moment(filterDate).format('YYYY') + " and " : "";
		//
		filter += fTitle + fDate;
		filter = filter.substring(0, filter.lastIndexOf(" and "));
		return filter;
	}

	handleClearFilter = () => {
		this.setState({
			filterDate: "",
			filterTitle: "",
			page: 1
		}, () => {
			this.handleFilter();
		});
	}

	// PAGINATION
	handleSearch = (page) => {
		this.props.ShowLoader(<Loader />);
		this.props.setURLState({ page });
	}

	//Modal to show wich users read the message
	showUsersRead = (item) => {
		this.props.SweetAlert(
			<Alert
				title={false}
				onConfirm={() => this.props.HideAlert()}
				confirmBtnCssClass={
					this.props.classes.success + " " + this.props.classes.button
				}
				showCancel={false}
				customClass={styles.modalClass}
				confirmBtnText="FECHAR"
			>
				{
					<MaterialTable
						components={{
							Container: props => <Paper {...props} elevation={0} />,
							Toolbar: props => <MTableToolbar {...props} classes={{ root: styles.tableTitle }} />,
							Cell: props => <MTableCell {...props} style={{ padding: '14px 40px 12px 0', textAlign: 'left' }} />,
						}}
						localization={{
							toolbar: {
								searchPlaceholder: "Buscar"
							},
							pagination: {
								labelDisplayedRows: '{from}-{to} de {count}',
								labelRowsSelect: 'linhas'
							},
							body: {
								emptyDataSourceMessage: 'Sem conteúdo para exibir'
							}
						}}
						title="lido por"
						columns={[
							{ title: 'CPF', field: 'userName', headerStyle: { paddingLeft: 0, textAlign: 'left' } },
							{ title: 'Nome', field: 'userFullName', headerStyle: { paddingLeft: 0, textAlign: 'left' } },
							{ title: 'Data da leitura', field: 'readAt', type: 'date', headerStyle: { paddingLeft: 0, textAlign: 'left' }, render: (x) => { return moment(x.readAt).format("DD/MM/YYYY - HH:mm") } },
						]}
						data={item}
					/>
				}
			</Alert>
		);
	}

	render() {
		const { t, classes, } = this.props;
		const { messagesData, page, pageSize, recordCount, filterDate, filterTitle } = this.state;
		return (
			<CommonPage bottomAppBar={
				<CustomPagination
					pageSize={pageSize}
					recordCount={recordCount}
					offset={pageSize * (page - 1)}
					clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
				/>
			}>
				{this.state.alert}
				<DocumentTitle title={'Connectyse - Status dos envios'} />
				<GridContainer>
					<GridItem xs={12}>
						<Heading
							textAlign="left"
							title={t("MENU_SEND_STATUS")}
						/>
						<Breadcrumbs items={this.state.breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer style={{ marginBottom: '40px' }}>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<h5><b>Filtrar por:</b></h5>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<CustomInput
												id="filterTitle"
												name="filterTitle"
												labelText="Título"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													value: filterTitle,
													onChange: this.handleChange.bind(this, "filterTitle"),
													type: "text"
												}}
											/>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={6} md={3} lg={3}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<Datetime
												id="filterDate"
												name="filterDate"
												timeFormat={false}
												onChange={this.handleChange.bind(this, "filterDate")}
												value={filterDate ? moment(filterDate).format("L") : ''}
												renderInput={(props) => {
													return (
														<CustomInput
															labelText={"Data"}
															formControlProps={{
																fullWidth: true
															}}
															inputProps={{ ...props }}

														/>
													);
												}}
											/>
										</FormControl>
									</GridItem>
									{/* BUTTON */}
									<GridItem xs={12} sm={12} md={3} style={{ marginTop: '22px' }}>
										<Button size="sm" color="primary" onClick={this.handleFilter.bind(this)} >Filtrar</Button>
										<Button size="sm" color="whiteButtonColor" onClick={this.handleClearFilter.bind(this)}>Limpar</Button>
									</GridItem>
								</GridContainer>
								{/* TABLE */}
								<Table hover
									tableHead={["Título", "Data", "Enviados", "Lidos", "Ações"]}
									tableData={
										messagesData.map((item, index) => ([
											this.createColumnInfo(item, 0),
											this.createColumnInfo(item, 2),
											item.sent,
											<div>
												{item.read}
												<Drafts style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: '5px' }} color="primary" onClick={this.showUsersRead.bind(this, item.users.filter(x => x.read))} />
											</div>,
											<div>
												<Button
													justIcon
													size="sm"
													color="primary"
													onClick={this.handleShow.bind(this, item)}
												>
													<Search className={classes.icons} />

												</Button>
												<Button
													justIcon
													size="sm"
													color="primary"
													onClick={this.handleDelete.bind(this, item.id)}
												>
													<DeleteIcon className={classes.icon} />
												</Button>

											</div>
										]))}


									customCellClasses={[classes.left, classes.left, classes.left]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customClassesForCells={[0, 1, 2]}
									customHeadCellClasses={[classes.left, classes.left, classes.left]}
									// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
									customHeadClassesForCells={[0, 1, 2]}
								/>
							</CardBody>

						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}
}

StatusMessages.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		lsMessagesData: state.messages.lsMessagesData,
		messagesDataFailed: state.messages.messagesDataFailed,
		messagesDataPost: state.messages.messagesDataPost,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetMessages, GetMessageById, DeleteMessage, ShowLoader, HideLoader, SweetAlert, HideAlert }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({ filterTitle: '', filterDate: null, page: 1 }),
	withStyles(sweetAlertStyle),
	withTranslation(),
)(StatusMessages);