import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import LogoHeader from '../../assets/img/simbolo_header_CONNECTYSE.svg';
import defaultAvatar from "assets/img/default-avatar.png";
import { Avatar, ListItem, List, Icon, ListItemText, ButtonBase, Hidden } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { ShellContext } from 'utils/shell';
import cx from "classnames";
import { successColor } from "assets/jss/material-dashboard-pro-react.jsx";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: "103345",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  focusVisible: {},
  imageButton: {
    height: '34px',
    margin: '0 10px',
    cursor: 'auto'
  },
  buttonDarkBG: {
    backgroundColor: successColor[0]
  },
  buttonLightBG: {
    backgroundColor: "white"
  },
  widthLarge: {
    width: '79px'
  },
  widthShort: {
    width: '50px'
  },
  paper: {
    boxShadow: '0 1px 5px #d3d4d5',
    width: '202px',
    borderRadius: '0'
  },
}));

const ImageButton = ({ image, ...props }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      focusRipple
      focusVisibleClassName={classes.focusVisible}
      {...props}
    >
      <img src={image} alt="Logo" height={'50px'} />
    </ButtonBase>
  )
}

const AdminNavbar = ({ handleDrawerToggle, brandText, user, t }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const isMenuOpen = Boolean(anchorEl);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar} color="secondary">
        <Toolbar disableGutters style={{ minHeight: '50px', height: '50px' }}>
          <ShellContext.Consumer>
            {
              ({ drawerOpen, toggleDrawer, isMenuOpen }) =>
                <img
                  //onClick={toggleDrawer}
                  src={LogoHeader}
                  className={cx({ [classes.imageButton]: true, [classes.buttonDarkBG]: !drawerOpen, [classes.buttonLightBG]: drawerOpen })}
                  height={12}
                />
            }
          </ShellContext.Consumer>
          <Hidden mdUp>

            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="show-drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography className={classes.title} variant="h6" noWrap>
            {brandText}
          </Typography>

          <div className={classes.grow} />
          <div>

            <List dense component="nav" aria-label="User settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="user-menu"
                aria-label="user menu"
                onClick={handleClickUserMenu}
              >
                <Avatar style={{ margin: "0px 10px" }} alt="User Avatar" src={user.picture || defaultAvatar} />
                <ListItemText primary={user.given_name} />
                {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </List>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={isMenuOpen}
              onClose={handleMenuClose}
              style={{ zIndex: 103347 }}
              classes={{ paper: classes.paper }}
            >
              {/* <NavLink to="/"> */}
              <MenuItem onClick={handleMenuClose} style={{ color: "black" }}>
                <Icon color="secondary">
                  {"person"}
                </Icon>
                <span style={{ marginLeft: "5px" }}>{t("MENU_USER_MY_PROFILE")}</span>
              </MenuItem>
              {/* </NavLink> */}
              <NavLink to="/auth/login">
                <MenuItem onClick={handleMenuClose} style={{ color: "black" }}>
                  <Icon color="secondary">
                    {"directions_run"}
                  </Icon>
                  <span style={{ marginLeft: "5px" }}>{t("BUTTON_LOGOUT")}</span>
                </MenuItem>
              </NavLink>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  handleDrawerToggle: PropTypes.func.isRequired,
  sidebarMinimize: PropTypes.func.isRequired
};

export default withTranslation()(AdminNavbar);
