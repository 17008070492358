import * as actionTypes from '../actionsTypes';
import * as API from '../../api/draft/draft';

export const fetchDraftData = (data) => {
    return {
        type: actionTypes.FETCH_DRAFT_DATA,
        lsDraftsData: data
    };
};

export const fetchUserDraftData = (data) => {
    return {
        type: actionTypes.FETCH_USER_DRAFT_DATA,
        lsUserDraftsData: data
    };
};

export const fetchDraftById = (data) => {
    return {
        type: actionTypes.FETCH_DRAFT_DATA_BY_ID,
        draftData: data
    };
};

export const deleteDraftData = (data) => {
    return {
        type: actionTypes.DELETE_DRAFT_DATA,
        draftDelete: data
    };
}

export const postDraftData = (data) => {
    return {
        type: actionTypes.POST_DRAFT_DATA,
        draftDataPost: data
    };
}

// FAIL POST -------------------------------------

export const postDraftDataFailed = (error) => {
    return {
        type: actionTypes.POST_DRAFT_DATA_FAILED,
        draftsDataPostFailed: error
    };
}

// FAIL FETCH -------------------------------------

export const fetchDraftDataFailed = (error) => {
    return {
        type: actionTypes.FETCH_DRAFT_FAILED,
        draftDataFailed: error
    };
};

export const fetchUserDraftDataFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_DRAFT_FAILED,
        draftDataFailed: error
    };
};



//----------------------------------------------------

export const GetDraft = (_filter, _page, _itemsPerPage) => {
    return dispatch => {
        return API.GetDraft(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchDraftData(response));
            return response;
        }).catch(error => {
            console.error('error:', error);
            dispatch(fetchDraftDataFailed(error));
            //dispatch(Loader.hideLoader());
        });
    }
}

export const PostDraft = (data) => {
    return dispatch => (
        API.PostDraft(data).then(response => {
            dispatch(postDraftData(response));
            return response;
        }).catch(error => {
            console.error(error);
            return dispatch(postDraftDataFailed(error));
            //dispatch(Loader.hideLoader());                       
        })
    );
}

export const GetDraftUsersByUserName = (data) => {
    return dispatch => (
        API.GetDraftUsersByUserName(data).then(response => {
            dispatch(fetchUserDraftData(response));
            return response;
        }).catch(error => {
            console.error(error);
            return dispatch(fetchUserDraftDataFailed(error));
            //dispatch(Loader.hideLoader());                       
        })
    );
}

export const GetDraftById = (id) => {
    return dispatch => (
        API.GetDraftById(id).then(resp => {
            dispatch(fetchDraftById(resp));
            return resp;
        }).catch(error => {
            console.log(error);
            dispatch(fetchDraftDataFailed(error));
        })
    )
}

export const DeleteDraft = (data) => {
    return dispatch => (
        API.DeleteDraft(data).then(response => {
            dispatch(deleteDraftData(response));
        }).catch(error => {
            console.error(error);
            return dispatch(postDraftDataFailed(error));
            //dispatch(Loader.hideLoader());                       
        })
    );
}

