import { grayColor, customColors } from "assets/jss/material-dashboard-pro-react.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import dashboardStyle from "./dashboardStyle";

const sweetAlertStyle = {
  ...extendedFormsStyle,
  ...customSelectStyle,
  ...dashboardStyle,
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  actionButton: {
    marginRight: '2px',
    cursor: 'pointer',
    background: customColors[0],
    borderRadius: '4px',
    color: 'white',
    padding: '5px',
    fontSize: '14px',
    width: '25px'
  },
  menuItem: {
    color: customColors[0],
    textAlign: 'center',
    "&:hover": {
      color: '#fff !important'
    }
  },
  modalRelatorioBlock: {
    "h2,h3": {
      color: 'black',
    },
    "h4": {
      textTransform: 'uppercase',
      color: customColors[0],
      fontWeight: '600',
    }
  },
  iconCircle: {
    border: '3px solid ' + customColors[0],
    borderRadius: '50%',
    width: '75px',
    height: '75px',
    paddingTop: '10px',
    margin: 'auto'
  },
  imgUpload: {
    display: 'inline-grid',
    "& label": {
      cursor: 'pointer',
      color: 'white',
      borderRadius: '4px',
      background: customColors[0],
      padding: '8px 0',
      marginTop: '15px',
      width: '120px',
      textAlign: 'center'
    },
    margin: '10px 0',
    "& input[type='file']": {
      display: 'none',
    }
  },
  ...buttonStyle,
};

export default sweetAlertStyle;
