import React from 'react';
import Alert from "react-bootstrap-sweetalert";
import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';

// @material-ui/core components
import { withStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CustomTooltip from 'components/CustomTooltip/CustomTooltip.jsx';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from "components/Grid/GridContainer.jsx";
import FileUpload from "components/CustomUpload/ImgUpload.jsx";
import Loader from "components/Loader/Loader.jsx";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { PutCustomizationData, ImageUpload, ShowLoader, SweetAlert, HideAlert, HideLoader } from 'redux/actions';

const SettingsForm = ({ iniValues, ...props }) => {

    const dispatch = useDispatch();

    const colorCallback = (setFieldValue, name, color) => {
        setFieldValue(name, color);
    }

    const handleSave = async (values) => {
        dispatch(ShowLoader(<Loader />));
        if (values.fullLogoUri instanceof File) {
            let response = await dispatch(ImageUpload(values.fullLogoUri));
            if (response) {
                values.fullLogoUri = response[0];
            } else {
                alert('ERROR in update the images');
                return;
            }
        }
        if (values.shortLogoUri instanceof File) {
            let response = await dispatch(ImageUpload(values.shortLogoUri));
            if (response) {
                values.shortLogoUri = response[0];
            } else {
                alert('ERROR in update the images');
                return;
            }
        }

        if (typeof values.fullLogoUri === "string" && typeof values.shortLogoUri === "string") {
            dispatch(PutCustomizationData(values)).then(rest => {
                if (rest) {
                    localStorage.removeItem('config');
                    localStorage.setItem('config', JSON.stringify(rest));
                    dispatch(HideLoader());
                    dispatch(SweetAlert(
                        <Alert
                            success
                            style={{ display: "block" }}
                            title={"Dados alterados com sucesso!"}
                            onConfirm={() => dispatch(HideAlert())}
                            confirmBtnCssClass={
                                props.classes.button + " " + props.classes.primary
                            }
                            confirmBtnText={"OK"}
                        >
                            {"Por favor atualize a página ou efetue o login novamente para aplicar as alterações."}
                        </Alert>
                    ));
                };
            })
        } else {
            requiredImage();
            console.log('ERROR in update the images');
        }

    }

    const requiredImage = () => {
        dispatch(SweetAlert(
            <Alert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={false}
                onConfirm={() => { dispatch(HideAlert()); dispatch(HideLoader()); }}
                confirmBtnCssClass={
                    props.classes.button + " " + props.classes.primary
                }
                confirmBtnText={"Ok"}
            >
                {"Por favor anexe os logos no formato PNG ou SVG."}
            </Alert>
        ));
    }
    return (
        <Formik
            initialValues={iniValues}
            validationSchema={
                Yup.object({
                    primaryColor: Yup.string().required('* Campo obrigatório'),
                    secondaryColor: Yup.string().required('* Campo obrigatório'),
                })
            }
            onSubmit={
                (values) => {
                    handleSave(values);
                }
            }
            enableReinitialize
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <Form>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'left', marginTop: '40px' }}>
                            <span><b>Logos:</b></span>
                        </GridItem>
                        <Field name='fullLogoUri'>
                            {({ field, form }) => (
                                <>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <FormLabel
                                            className={props.classes.labelHorizontal}
                                            error={(form.touched.fullLogoUri && Boolean(form.errors.fullLogoUri))}
                                        >
                                            Logo menu aberto:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>

                                        <div className={props.classes.imgUpload}>
                                            <FileUpload file={form.values.fullLogoUri} width={180} />
                                            <label>
                                                {"SELECIONAR"}
                                                <input
                                                    accept="image/png, image/svg"
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    onChange={(event) => { setFieldValue("fullLogoUri", event.currentTarget.files[0]) }}
                                                />
                                            </label>
                                        </div>
                                        <CustomTooltip
                                            content={"Formato PNG ou SVG"}
                                            placement="right"
                                        />
                                    </GridItem>
                                </>

                            )}
                        </Field>
                        <Field name='shortLogoUri'>
                            {({ field, form }) => (
                                <>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <FormLabel
                                            className={props.classes.labelHorizontal}
                                            error={(form.touched.shortLogoUri && Boolean(form.errors.shortLogoUri))}
                                        >
                                            Logo menu recuado:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                                        <div className={props.classes.imgUpload}>
                                            <FileUpload file={form.values.shortLogoUri} width={110} />
                                            <label>
                                                {"SELECIONAR"}
                                                <input
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    onChange={(event) => { setFieldValue("shortLogoUri", event.currentTarget.files[0]) }}
                                                />
                                            </label>
                                        </div>
                                        <CustomTooltip
                                            content={"Formato PNG ou SVG"}
                                            placement="right"
                                        />
                                    </GridItem>
                                </>
                            )}
                        </Field>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'left', marginTop: '40px' }}>
                            <span><b>Cores:</b></span>
                        </GridItem>
                        <Field name='primaryColor'>
                            {({ field, form }) => (
                                <SketchExample touched={form.touched.primaryColor} inputColor={form.values.primaryColor} error={form.errors.primaryColor} field={field} form={form} label={'Cor principal'} labelGrid={3} parentCallBack={colorCallback.bind(this, setFieldValue, 'primaryColor')} classes={props.classes} />

                            )}
                        </Field>
                        <Field name='secondaryColor'>
                            {({ field, form }) => (
                                <SketchExample touched={form.touched.secondaryColor} inputColor={form.values.secondaryColor} error={form.errors.secondaryColor} field={field} form={form} label={'Cor secundária'} labelGrid={3} parentCallBack={colorCallback.bind(this, setFieldValue, 'secondaryColor')} classes={props.classes} />
                            )}
                        </Field>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                            <Button
                                size="sm"
                                color="primary"
                                onClick={
                                    () => {
                                        handleSubmit()
                                    }
                                }
                            >
                                Enviar
                            </Button>
                        </GridItem>
                    </GridContainer>
                </Form>
            )}
        </Formik>
    )
};


const SketchExample = ({ parentCallBack, field, label, touched, error, labelGrid, inputColor, ...props }) => {

    const [open, setOpen] = React.useState(false);
    const [color, setColor] = React.useState(inputColor);


    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (color) => {
        setColor(color.hex);
        parentCallBack(color.hex)
    };


    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: inputColor,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {

                zIndex: '2',
                position: 'absolute',
                bottom: '120%'
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <>
            <GridItem xs={12} sm={labelGrid} md={labelGrid} lg={labelGrid} xl={labelGrid} >
                <FormLabel
                    className={props.classes.labelHorizontal}
                    error={(touched && Boolean(error))}
                    style={{ paddingTop: '31px' }}
                >
                    {label}:
                </FormLabel>
            </GridItem>

            <GridItem xs={6} sm={(8 - labelGrid)} md={(8 - labelGrid)} lg={(8 - labelGrid)} xl={(8 - labelGrid)} >
                <FormControl
                    fullWidth
                >
                    <CustomInput
                        error={(touched && Boolean(error))}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            ...field,
                        }}
                    />
                </FormControl>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4} xl={4} >
                <ClickAwayListener onClickAway={handleClose}>
                    <div style={{
                        verticalAlign: 'middle',
                        transform: 'translateY(-50%)',
                        top: '60%',
                        position: 'relative'

                    }}>
                        <div style={styles.swatch} onClick={handleClick}>
                            <div style={styles.color} />
                        </div>

                        {open ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={handleClose} />
                            <SketchPicker disableAlpha color={color} onChange={handleChange} />
                        </div> : null}

                    </div>
                </ClickAwayListener>
            </GridItem>
        </>
    )

}


export default withStyles(styles)(SettingsForm);
