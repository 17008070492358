import * as actionTypes from '../actionsTypes';
import * as API from '../../api/integration/integration';
import * as Loader from '../loader';

export const fetchIntegration = (data) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_DATA,
        lsIntegration: data
    };
};

export const fetchIntegrationByIdData = (data) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_BY_ID_DATA,
        integrationByIdData: data
    };
}

// export const fetchDataTypes = (data) => {
//     return {
//         type: actionTypes.FETCH_DATA_TYPES,
//         lsDataTypes: data
//     }
// }

export const fetchBlobData = (data) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_BLOB_DATA,
        integrationBlobData: data
    }
}

// FAIL FETCH -------------------------------------

export const fetchIntegrationFailed = (error) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_FAILED,
        integrationDataFailed: error
    };
};

// export const fetchDataTypesFailed = (error) => {
//     return {
//         type: actionTypes.FETCH_DATA_TYPES_FAILED,
//         dataTypesFaield: error
//     };
// };

export const fetchBlobFailed = (error) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_BLOB_FAILED,
        integrationBlobFailed: error
    };
};

export const fetchIntegrationByIdFailed = (error) => {
    return {
        type: actionTypes.FETCH_INTEGRATION_BY_ID_FAILED,
        integrationByIdData: error
    };
};
//----------------------------------------------------

export const GetIntegration = (_filter, _page, _limitPage) => {
    return dispatch => {
        return API.GetIntegrationData(_filter, _page, _limitPage).then(response => {
            dispatch(fetchIntegration(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchIntegrationFailed(error));
            dispatch(Loader.hideLoader());
        });
    }
};

// export const GetDataTypes = () => {
//     return dispatch => (
//         API.GetDataTypes().then(response => {
//             dispatch(fetchDataTypes(response));
//             return response;
//         }).catch(error => {
//             console.error(error);
//             dispatch(fetchDataTypesFailed(error));
//             dispatch(Loader.hideLoader());
//         })
//     );
// }

export const GetIntegrationBlobById = (id) => {
    return dispatch => (
        API.GetBlobFileById(id).then(response => {
            dispatch(fetchBlobData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchBlobFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
}

export const GetIntegrationById = (id) => {
    return dispatch => (
        API.GetIntegrationById(id).then(response => {
            dispatch(fetchIntegrationByIdData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchIntegrationByIdFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
}

// FILTER INTEGRATION
export const FilterIntegration = () => {
    return dispatch => (
        API.FilterIntegration().then(response => {
            dispatch(fetchIntegration(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchIntegrationFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
}