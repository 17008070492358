// import config from 'config';
import axios from 'axios';

import {
    authHeader
} from "../../utils";
import jwt_decode from 'jwt-decode';

export const userService = {
    login,
    logout,
    getAll
};

function login(username, password) {
    const authData = {
        username,
        password,
        client_id: "099153c2625149bc8ecb3e85e03f0023",
        client_secret: "secret",
        grant_type: "password",
        //scope: "germini-api openid profile"
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(authData)
    };

    return fetch(`${process.env.REACT_APP_CONNECTYSE_IDENTITY_URL}core/oauth2/token`, requestOptions)
        .then(handleResponse)
        .then(user => {
            //console.log(user)
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', user.token);
            localStorage.setItem('user', JSON.stringify(user.user));

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            return user.user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('config');
    localStorage.removeItem('i18next_pt-BR-common');
    localStorage.removeItem('i18next_es-common');
    localStorage.removeItem('i18next_en-common');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`http://localhost:5000/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    //console.log("RESPONSE", response);
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        const user = {
            user: jwt_decode(data.access_token),
            token: data.access_token
        };
        return user;
    });
}