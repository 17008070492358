import React from "react";

import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f3f3f3',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: "0px 0px 2px #808080",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',

    },
}))(Tooltip);

const useStyles = makeStyles({
    icon: {
        verticalAlign: 'middle',
        color: '#686767',
        cursor: 'pointer',
        position: 'relative',
        top: '35%',
        transform: 'translateY(-50%)'
    },
});

const CustomTooltip = ({ content, placement, ...props }) => {

    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div style={{ padding: '10px 0 0 10px', lineHeight: 'auto' }}>
                <HtmlTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement={placement ? placement : 'left'}
                    title={content}
                >
                    <Icon onClick={handleTooltipOpen} className={classes.icon}>info</Icon>
                </HtmlTooltip>
            </div>
        </ClickAwayListener>
    );
}
export default CustomTooltip;