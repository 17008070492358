import { authentication } from "./authenticate";

import dashboard from "./dashboard";
import config from "./systemConfig";
import integration from './integration/integration';
import user from './user/user';
import appDataType from "./app_admin/dataType/dataType";

// GERAL
import images from "./images";
import sweetAlert from "./sweetAlert";
import loader from "./loader";
import location from "./location";
import messages from "./messages/messages";
import drafts from "./draft/draft";

import { users } from "./user";
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users,
  authentication,
  sweetAlert,
  loader,
  location,
  dashboard,
  integration,
  user,
  config,
  appDataType,
  messages,
  images,
  drafts
});

export default createRootReducer;