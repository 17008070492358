import React, { Fragment } from "react";
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import * as _ from "lodash";
import DocumentTitle from 'react-document-title';
import Alert from "react-bootstrap-sweetalert";

// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/Loader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Heading from "components/Heading/Heading";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';

import FormikForm from './forms/messageForm.jsx'
// Utils
import compose from "utils/compose";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import styles from './message.module.scss';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetUsers, GetDataTypes, GetDraftById, PostMessage, ShowLoader, HideLoader, GetDraft, GetDraftUsersByUserName, PostDraft, SweetAlert, HideAlert } from 'redux/actions';

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";


class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                id: null,
                internalTitle: '',
                title: '',
                description: '',
                users: [],
                allUsers: false,
            },
            breadcrumbItems: [
                { name: "Home" },
                { name: "Mensageria" },
                { name: "Enviar", last: true },
            ],
        };
    }

    componentDidMount() {
        const id = this.props.match && this.props.match.params.id;
        this.props.GetDraftById(id);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.messageFormData !== this.props.messageFormData) {
            if (this.props.messageFormData.isSubmitted) {
                this.handleSubmit(this.props.messageFormData.message, this.props.messageFormData.isDraft)
            }
        }

        if (prevProps.messageDataPost !== this.props.messageDataPost) {
            this.setState({ messageDataPost: this.props.messageDataPost })
        }
        if (prevProps.messageDataPost !== this.props.messageDataPost) {
            this.props.HideLoader();
        }
        if (prevProps.draftData !== this.props.draftData) {
            if (this.props.draftData.items[0] !== undefined) {
                let vals = {
                    id: this.props.draftData.items[0].id,
                    internalTitle: this.props.draftData.items[0].detail.internalTitle,
                    title: this.props.draftData.items[0].detail.title,
                    description: this.props.draftData.items[0].detail.description,
                    allUsers: this.props.draftData.items[0].allUsers,
                    users: [],
                }

                if (this.props.draftData.items[0].allUsers === false) {
                    let user = _.map(this.props.draftData.items[0].users, 'userName');
                    this.props.GetDraftUsersByUserName(user).then(resp => {
                        resp.map((item, key) => {
                            let newObjt = {
                                active: item.active,
                                fullName: item.fullName,
                                id: item.id,
                                label: item.userName + " - " + item.fullName,
                                userName: item.userName,
                            }
                            vals.users.push(newObjt);
                        })
                        this.setState({ initialValues: vals });
                    })
                } else {
                    this.setState({ initialValues: vals });
                }
            }
            this.props.HideLoader();
        }
    }

    handleMessageCheckAllAlert = () => {
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block", fontSize: '8px' }}
                title={"Gostaria de enviar a mensagem para todos da sua lista?"}
                showCancel={true}
                closeOnClickOutside={false}
                onConfirm={() => this.handleClearUserChips()}
                onCancel={() => this.handleCancelSwitch()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText={this.props.t("BUTTON_YES")}
                cancelBtnText={this.props.t("BUTTON_NO")}
            >
                <div style={{ fontSize: '14px' }}>
                    Com esse item selecionado a sua mensagem será
                <br></br>
                    enviada para todos da sua lista de contatos.
                </div>
            </Alert>
        );
    }

    handleSaveAsDraft = (values, clear) => {
        this.props.ShowLoader(<Loader />);
        this.props.PostDraft(values).then(resp => {
            if (!this.props.draftsDataPostFailed) {
                if (clear) {
                    this.handleClearValues(values);
                    this.handleMessageSentAlert(values.isDraft);
                } else {
                    this.handleEdit(resp.id, values)
                }
                this.props.HideLoader();
            } else {
                this.handleMessageSentAlertFail(values.isDraft)
                this.props.HideLoader();
            }
        });
    }

    handleSaveAsMessage = (values) => {
        this.props.HideAlert();
        this.props.ShowLoader(<Loader />);
        this.props.PostMessage(values.id).then(resp => {
            if (!this.props.messageDataPostFail) {
                this.handleMessageSentAlert(values.isDraft);
                this.handleClearValues(values);
                this.props.HideLoader();
            } else {
                this.handleMessageSentAlertFail(values.isDraft)
                this.props.HideLoader();
            }
        })
    }

    handleClearValues = (values) => {
        values.internalTitle = '';
        values.title = '';
        values.description = ''
        values.users = [];
    }

    handleClearUrl = () => {
        this.props.history.push(`/admin/messages/send/new`);
        this.props.HideAlert()
    }

    handleSubmit = (values, draft) => {
        if (draft) {
            this.handleSaveAsDraft(values, true);
        } else {
            this.handleSaveAsDraft(values, false);
        }
    }

    handleMessageSentAlert(draftFlag) {
        this.props.SweetAlert(
            <Alert
                success
                style={{ display: "block" }}
                title="Mensagem enviada com sucesso!"
                title={draftFlag ? "Rascunho salvo com sucesso!" : "Mensagem enviada com sucesso!"}
                onConfirm={() => this.handleClearUrl()}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
            >
                {draftFlag ? "Para visualizar seus rascunhos clique" : "Para visualizar suas mensagens clique"}
                <NavLink to={draftFlag ? "/admin/messages/draft" : "/admin/messages/statusSend"} style={{ marginLeft: '3px' }}>
                    aqui.
                    </NavLink>

            </Alert>
        );
    }

    handleMessageSentAlertFail(draftFlag) {
        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title={draftFlag ? "Não foi possível salvar seu rascunho." : "Não foi possível enviar sua mensagem."}
                onConfirm={() => this.handleClearUrl()}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
            >
                Tente novamente por favor.
                </Alert>
        );
    }


    handleShowMessageData = (message) => {

        this.props.SweetAlert(
            <Alert
                style={{ display: "block", marginLeft: '-386px', marginTop: '-400px !important', top: '80px', width: '860px', fontSize: '10px' }}
                customClass={styles.modalShowData}
                title={'Visualização da mensagem a ser enviada'}
                onConfirm={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.danger + " " + this.props.classes.button + " " + styles.buttonModal
                }
                showCancel={false}
                confirmBtnText="FECHAR"
            >
                <div style={{ display: 'flex' }}>
                    <GridContainer justify="flex-start">

                        <GridItem style={{ display: 'flex' }}>
                            <FormLabel
                                style={{ marginTop: '-8px', color: 'black', fontWeight: 'bold' }}
                                className={this.props.classes.labelHorizontal}
                            >
                                Para:
                                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9} lg={9} >
                            <FormControl
                                fullWidth
                            // className={classes.formControl}
                            >
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        disabled: true,
                                        name: 'internalTitle',
                                        value:
                                            message.allUsers ? 'Todos os contatos da lista' : message.users.length + ' Destinátarios',
                                        type: "text",
                                    }}
                                />
                            </FormControl>
                        </GridItem>


                        <GridItem style={{ display: 'flex' }}>
                            <FormLabel
                                style={{ marginTop: '-8px', color: 'black', fontWeight: 'bold' }}
                                className={this.props.classes.labelHorizontal}
                            >
                                Descrição do lote:
                                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9} lg={9} >
                            <FormControl
                                fullWidth
                            // className={classes.formControl}
                            >
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        // onChange: change.bind(null, "internalTitle"),
                                        disabled: true,
                                        name: 'internalTitle',
                                        value: message.detail.internalTitle,
                                        type: "text",
                                    }}
                                />
                            </FormControl>
                        </GridItem>

                        <GridItem style={{ display: 'flex' }}>
                            <FormLabel
                                style={{ marginTop: '-8px', color: 'black', fontWeight: 'bold' }}
                                className={this.props.classes.labelHorizontal}
                            >
                                Título:
                                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9} lg={9} >
                            <FormControl
                                fullWidth
                            // className={classes.formControl}
                            >
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        // onChange: change.bind(null, "internalTitle"),
                                        disabled: true,
                                        name: 'internalTitle',
                                        value: message.detail.title,
                                        type: "text",
                                    }}
                                />
                            </FormControl>
                        </GridItem>


                        <GridItem xs={12} sm={12} md={12} lg={12} >
                            <FormLabel
                                style={{ marginTop: '-8px', color: 'black', fontWeight: 'bold' }}
                                className={this.props.classes.labelHorizontal}
                            >
                                Mensagem:
                                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} >
                            <FormControl
                                fullWidth
                            // className={classes.formControl}
                            >
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        // onChange: change.bind(null, "internalTitle"),
                                        disabled: true,
                                        name: 'internalTitle',
                                        value: message.detail.description,
                                        type: "text",
                                    }}
                                />
                            </FormControl>
                        </GridItem>



                    </GridContainer>
                </div>
                <hr></hr>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                    <Button size="sm" color="primary" onClick={() => this.handleSaveAsMessage(message)}>Enviar</Button>
                    <Button size="sm" color="greenBorderButtonColor" onClick={() => this.props.HideAlert()} >Cancelar</Button>
                </GridItem>
            </Alert>
        );
    }

    handleEdit = (id, message) => {
        this.props.history.push(`/admin/messages/send/edit/${id}`);
        message['id'] = id;
        this.handleShowMessageData(message);
    }


    render() {
        const { classes, t } = this.props;
        const { initialValues } = this.state;

        return (
            <Fragment >
                <GridContainer>
                    <DocumentTitle title={'Connectyse - Enviar mensagem'} />
                    <GridItem xs={12}>
                        <Heading
                            textAlign="left"
                            title={t("MENU_SEND")}
                        />
                        <Breadcrumbs items={this.state.breadcrumbItems} />
                        <Card>
                            <CardBody>
                                <GridContainer justify="center" >
                                    <GridItem xs={12} sm={12} md={12} lg={12} >
                                        <h5><b>Parametrização da mensagem</b></h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}  >
                                        <FormikForm
                                            {...this.props}
                                            iniValues={initialValues}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        lsUsers: state.user.lsUsers,
        lsDrafts: state.drafts.lsDraftsData,
        lsUserDrafts: state.drafts.lsUserDraftData,
        messageDataPost: state.messages.messageDataPost,
        messageData: state.messages.messageData,
        draftData: state.drafts.draftData,
        messageFormData: state.messages.messageFormData,

        //errors catching
        draftsDataPostFailed: state.drafts.draftsDataPostFailed,
        messageDataPostFail: state.messages.messageDataPostFail

    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetUsers, PostMessage, GetDraftById, GetDataTypes, ShowLoader, HideLoader, GetDraft, GetDraftUsersByUserName, PostDraft, SweetAlert, HideAlert }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedFormsStyle, sweetAlertStyle),

    withTranslation(),
)(Message);