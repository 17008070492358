import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import axios from 'axios';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Login from "./components/Login";
import authStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";
import Footer from "components/Footer/Footer.jsx";
import login from "assets/img/bg_login.jpg";

import { GetCustomizationData } from 'redux/actions';

// Utils
import compose from "utils/compose";


// window.onbeforeunload = function () {
// 	axios.get(`core/api/Customization/Get`)
// 		.then(response => {
// 			debugger;
// 			localStorage.setItem('customConfig', JSON.stringify(response.data));
// 		});
// }


class Auth extends React.Component {

	componentDidMount() {
		this.props.GetCustomizationData().then(config => {
			if (config) localStorage.setItem('config', JSON.stringify(config));
		})
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.wrapper} ref="wrapper">
				<div className={classes.fullPage} style={{ backgroundImage: "url(" + login + ")" }}>
					<Route
						path="/auth/login"
						component={Login}
					/>
					<Footer white />
				</div>
			</div>
		);
	}
}

Auth.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetCustomizationData }
	, dispatch);

export default compose(
	connect(null, mapDispatchToProps),
	withStyles(authStyle)
)(Auth);