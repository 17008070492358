// @material-ui/icons

import Dash from "pages/Dashboard";
import Integration from "pages/Integration";
import Detail from 'pages/Integration/Detail';
import User from "pages/User";
import DataType from 'pages/DataType';
import Construction from "pages/Construction";
import Settings from "pages/Settings";
import Message from "pages/Message";
import StatusMessage from "pages/Message/Status"
import DraftBox from "pages/Message/Draft/DraftBox";

export const dashRoutes = [{
    path: "/dashboard",
    name: 'MENU_DASHBOARD',
    sidebar: true,
    icon: "icon-dashboard",
    layout: "/admin",
    component: Dash
},
{
    path: "/integration",
    name: "MENU_INTEGRATION",
    sidebar: true,
    icon: 'icon-integracao',
    layout: '/admin',
    component: Integration
},
{
    path: "/integrationDetail/:id",
    name: "MENU_INTEGRATION_DETAIL",
    sidebar: false,
    icon: 'icon-integracao',
    layout: '/admin',
    component: Detail
},
{
    path: "/user",
    name: "MENU_REGISTER",
    sidebar: true,
    icon: 'icon-cadastros',
    layout: '/admin',
    component: User
},
{
    collapse: true,
    name: "MENU_MESSAGE",
    sidebar: true,
    icon: 'icon-mensageria',
    state: 'pageCollapseMngt',
    views: [{
        path: "/messages/send/new",
        name: "MENU_SEND",
        sidebar: true,
        icon: 'icon-enviar',
        iconSmall: true,
        layout: '/admin',
        component: Message
    },
    {
        path: "/messages/send/edit/:id",
        name: "MENU_SEND",
        sidebar: false,
        icon: 'icon-enviar',
        iconSmall: true,
        layout: '/admin',
        component: Message
    },
    {
        path: "/messages/draft",
        name: "MENU_DRAFT",
        sidebar: true,
        icon: 'icon-rascunho',
        iconSmall: true,
        layout: '/admin',
        component: DraftBox
    },
    {
        path: "/messages/statusSend",
        name: "MENU_SEND_STATUS",
        sidebar: true,
        icon: 'icon-status_envio',
        iconSmall: true,
        layout: '/admin',
        component: StatusMessage
    }
    ]
},
{
    collapse: true,
    name: "MENU_ADM_APP",
    sidebar: true,
    icon: 'icon-adm_app',
    state: 'pageCollapseCons',
    views: [{
        path: "/dataType",
        name: "MENU_DATA_TYPE",
        sidebar: true,
        icon: 'icon-tipo_dados',
        iconSmall: true,
        layout: '/admin',
        component: DataType
    },
    {
        path: "/functions",
        name: "MENU_FUNCTIONS",
        sidebar: true,
        icon: 'icon-funcionalidades',
        iconSmall: true,
        layout: '/admin',
        component: Construction
    }]
},


{
    path: "/settings",
    name: "MENU_SETTINGS",
    sidebar: true,
    icon: 'icon-configuracoes',
    layout: '/admin',
    component: Settings
}
];

