import axios from 'axios';

// GET
export const GetDraft = (_filter, _page, _itemsPerPage) => {
    let page = '';
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }
    return axios.get(`/core/api/MessageDrafts/GetAll${_filter}${page}`).then(response => response.data);
}

export const GetDraftById = (id) => {
    let filter = `?$filter=Id eq '${id}'`
    return axios.get(`/core/api/MessageDrafts/GetAll${filter}`).then(response => response.data);
}

// POST /api/MessageDrafts/GetDraftUsersByUserName
export const GetDraftUsersByUserName = (user) => {
    return axios.post(`/core/api/MessageDrafts/GetDraftUsersByUserName `, user).then(response => response.data);
}


// POST
export const PostDraft = (obj) => {
    return axios.post(`/core/api/MessageDrafts/Upsert `, obj)
        .then(response => response.data);
}

export const DeleteDraft = (id) => {
    return axios.post(`/core/api/MessageDrafts/Delete?Id=${id}`)
        .then(response => response.data);
}
