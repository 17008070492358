import * as actionTypes from '../actionsTypes';
import * as API from '../../api/messages/messages';

export const fetchMessagesData = (data) => {
    return {
        type: actionTypes.FETCH_MESSAGE_DATA,
        lsMessagesData: data
    };
};

export const postMessageData = (data) => {
    return {
        type: actionTypes.POST_MESSAGE_DATA,
        messageDataPost: data
    };
}

export const deleteMessageData = (data) => {
    return {
        type: actionTypes.DELETE_MESSAGE_DATA,
        messageDelete: data
    };
}

// FAIL POST -------------------------------------

export const postMessageDataFail = (error) => {
    return {
        type: actionTypes.POST_MESSAGE_DATA_FAIL,
        messageDataPostFail: error
    };
}

export const fetchMessageById = (data) => {
    return {
        type: actionTypes.FETCH_MESSAGE_DATA_BY_ID,
        messageData: data
    };
};

// FAIL FETCH -------------------------------------

export const fetchMessageDataFailed = (error) => {
    return {
        type: actionTypes.FETCH_MESSAGE_FAILED,
        messageDataFailed: error
    };
};

//----------------------------------------------------

export const GetMessages = (_filter, _page, _itemsPerPage) => {
    return dispatch => {
        return API.GetMessages(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchMessagesData(response));
            return response;
        }).catch(error => {
            console.error('error:', error);
            dispatch(fetchMessageDataFailed(error));
            //dispatch(Loader.hideLoader());
        });
    }
}

export const GetDraftMessages = (_filter, _page, _itemsPerPage) => {
    return dispatch => (
        API.GetDraftMessages(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchMessagesData(response));
            return response;
        }).catch((error) => {
            console.error('error:', error);
            dispatch(fetchMessageDataFailed(error));
            // dispatch(Loader.hideLoader());
        })
    );
};

export const GetMessageById = (id) => {
    return dispatch => (
        API.GetMessageById(id).then(resp => {
            dispatch(fetchMessageById(resp));
            return resp;
        }).catch(error => {
            console.log(error);
            dispatch(fetchMessageDataFailed(error));
        })
    )
}

// POST
export const PostMessage = (data) => {
    return dispatch => (
        API.PostMessage(data).then(response => {
            dispatch(postMessageData(response));
            return response;
        }).catch(error => {
            console.error(error);
            return dispatch(postMessageDataFail(error));
            //dispatch(Loader.hideLoader());                       
        })
    );
}

export const DeleteMessage = (data) => {
    return dispatch => (
        API.DeleteMessage(data).then(response => {
            dispatch(deleteMessageData(response));
        }).catch(error => {
            console.error(error);
            return dispatch(postMessageDataFail(error));
            //dispatch(Loader.hideLoader());                       
        })
    );
}

export const MessageFormData = data => {
    return dispatch => (
        dispatch({
            type: actionTypes.MESSAGE_FORM_DATA,
            messageFormData: data,
        })
    )
}
