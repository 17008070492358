import React, { Fragment } from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames";
import DocumentTitle from 'react-document-title';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Divider, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx';
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Loader from "components/Loader/Loader.jsx";
import Danger from "components/Typography/Danger.jsx";
import Heading from "components/Heading/Heading.jsx";
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';

// Utils
import compose from "utils/compose";
import { stateProvinces } from "utils/stateProvince";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import styles from './user.module.scss';
import { customColors } from "assets/jss/material-dashboard-pro-react.jsx";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetUsers, GetDataTypes, GetBusinessPartnerDataTypes, ChangePassword, ShowLoader, HideLoader } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';

import CommonPage from "components/Pages/CommonPage";
import { connectRouterState } from "utils/connectRouterState";

class User extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			lsUsersData: [],
			userTable: [],

			inputCodigo: "",
			inputName: "",
			inputEmail: "",
			inputLogin: "",
			inputStateProvince: "",
			inputStatus: "",
			isOnline: "",

			filter: "",

			itemsPerPage: 20,
			page: 1,
			recordCount: 0,
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Cadastros", last: true },
			],
			alert: null,
			show: false,
		};

		this.hideAlert = this.hideAlert.bind(this);
		this.successChangePWD = this.successChangePWD.bind(this);
		moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetDataTypes().then(() => {
			this.setState({ ...this.props.urlState });
			this.props.GetUsers(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page', ''), this.state.itemsPerPage);
		})

	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState');
		let urlState = _.get(this.props, 'urlState');
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({ ...urlState });
			this.props.GetUsers(this.stringifyFilter(urlState), urlState.page, this.state.itemsPerPage);
		}

		if (prevProps.lsUsers !== this.props.lsUsers) {
			this.setState({
				lsUsersData: this.props.lsUsers.items,
				usersTotalCount: this.props.lsUsers.count
			});
			this.props.GetDataTypes().then(resp => {
				this.createTableObj(this.props.lsUsers.items, resp);
			})

			this.props.HideLoader();
		}

		if (prevProps.userPwd !== this.props.userPwd) {
			this.props.HideLoader();
		}
		if (prevProps.lsDataTypes !== this.props.lsDataTypes) {

		}
		if (prevProps.userPwdFailed !== this.props.userPwdFailed) {
		}

		if (prevProps.usersDataFailed !== this.props.usersDataFailed) {
			this.props.HideLoader();
		}
	}

	createTableObj = (data, dataTypes) => {
		let items = _.orderBy(_.map(data, item => {
			let obj = {
				relatoriosUpdateDate: item.reportsInfos,
				coworkerCode: _.get(item, 'businessPartners[0].businessPartner.codigoCliente', ''),
				id: item.id,
				province: item.address.stateProvince,
				city: item.address.city,
				firstName: item.firstName,
				userName: item.userName,
				email: item.email,
				businessPartners: item.businessPartners,
				updatedAt: item.updatedAt,
				active: item.active,
				isOnline: item.insights.active,
				version: item.insights.appVersion ? item.insights.appVersion : 'Não identificada',
				lastAccess: item.insights.startedAt === '0001-01-01T00:00:00+00:00' ? 'Não rastreado' : moment(item.insights.startedAt).format('L') + " " + moment(item.insights.startedAt).format('LTS'),
				useTime: item.insights.startedAt === '0001-01-01T00:00:00+00:00' ? 'Não rastreado' : moment(moment(item.insights.startedAt).diff(moment(item.insights.beatedAt))).format('mm:ss') + ' mm:ss'
			}
			return obj;
		}), ["firstName", "asc"]);
		//
		this.setState({ userTable: _.without(items, undefined) });
	}

	handleChange = (event, v) => {
		const id = event.target ? event.target.id || event.target.name : event;
		let value = event.target ? event.target.value : v;
		value = v.target.value;
		this.setState({ [id]: value });
	};

	handleChangePWD = (item) => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block" }}
					title="Requisição de nova senha"
					onConfirm={() => this.successChangePWD(item)}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
					cancelBtnCssClass={
						this.props.classes.button + " " + this.props.classes.primary
					}
					confirmBtnText="Sim"
					cancelBtnText="Não"
					showCancel
				>
					Ao clicar em "SIM", uma nova senha será gerada e enviada ao usuário. <Danger><b>Esta ação não poderá ser desfeita.</b></Danger>
					Você deseja pedir a troca da senha de <b>{item.firstName}</b>?
			  </SweetAlert>
			)
		});
	}
	handleShowUserData = (item) => {
		this.setState({
			alert: (
				<SweetAlert
					style={{ display: "block", marginLeft: '-386px', marginTop: '-400px !important', top: '80px', width: '860px' }}
					customClass={styles.modalStyle}
					title={'Visualizar dados de usuário'}
					onConfirm={() => this.hideAlert()}
					confirmBtnCssClass={
						this.props.classes.danger + " " + this.props.classes.button + " " + styles.buttonModal
					}
					showCancel={false}
					confirmBtnText="Fechar"
				>
					<div style={{ display: 'flex' }}>
						<CustomInput
							labelText="Código do cooperado"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								disabled: true,
								value: item.coworkerCode,
								type: "text",
							}}
						/>
						<CustomInput
							labelText="CPF"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.userName.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4"),
								disabled: true,
								type: "text",
							}}
						/>
					</div>
					<div style={{ display: 'flex' }}>

						<CustomInput
							labelText="Nome"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.firstName,
								disabled: true,
								type: "text",
							}}
						/>
						<CustomInput
							labelText="E-mail"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.email,
								disabled: true,
								type: "text",
							}}
						/>
					</div>
					<div style={{ display: 'flex' }}>
						<CustomInput
							labelText="Versão do aplicativo"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.version,
								disabled: true,
								type: "text",
							}}
						/>
						<CustomInput
							labelText="Último acesso"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.lastAccess,
								disabled: true,
								type: "text",
							}}
						/>
					</div>
					<div style={{ display: 'flex' }}>
						<CustomInput
							labelText="Cidade/Estado"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.city + ' - ' + item.province,
								disabled: true,
								type: "text",
							}}
						/>
						<CustomInput
							labelText="Permanência no App"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: item.useTime,
								disabled: true,
								type: "text",
							}}
						/>
					</div>
					<Divider variant="middle" style={{ margin: '15px 0 ' }} />
					<span style={{ color: '#3c4858', fontSize: '13px' }}>{"Ultima atualização: " + moment(item.updatedAt).format('L')}</span>
				</SweetAlert>
			)
		});
	}

	handleShowDetails = async (item) => {
		this.props.ShowLoader(<Loader />);
		const array = await this.props.GetBusinessPartnerDataTypes(item.businessPartners[0].businessPartnerId);

		if (array.length > 0) {
			this.props.HideLoader();
			this.setState({
				alert: (
					<SweetAlert
						style={{ display: "block", marginLeft: '-386px', marginTop: '-400px !important', top: '80px', width: '860px' }}
						customClass={styles.modalStyle}
						title={'Atualização de relatórios'}
						onConfirm={() => this.hideAlert()}
						confirmBtnCssClass={
							this.props.classes.danger + " " + this.props.classes.button + " " + styles.buttonModal
						}
						showCancel={false}
						confirmBtnText="Fechar"
					>
						<h3><b>Dados da atualização</b></h3>
						<GridContainer>
							{
								array.map(dT => {
									return (
										<GridItem xs={12} md={6} style={{ marginTop: '20px' }}>
											<div className={this.props.classes.modalRelatorioBlock}>
												<div className={this.props.classes.iconCircle}>
													<span className={dT.description.iconUri} style={{ fontSize: '50px', color: customColors[0] }}></span>
												</div>
												<div>
													<h4><b>{dT.description.title}</b></h4>
													{dT.updatedAt && <span>Última atualização: {moment(dT.updatedAt).format("L")}</span>}
												</div>
											</div>
										</GridItem>
									)
								})
							}
						</GridContainer>
					</SweetAlert>
				)
			});
		}
	}


	successChangePWD(item) {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block" }}
					title="Nova senha requisitada."
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.greenButtonColor
					}
				>
					Sua senha será enviada no e-mail <b>{item.email}</b>.
			</SweetAlert>
			)
		});
		this.props.ShowLoader(<Loader />);
		this.props.ChangePassword(item.userName);
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}

	handleEdit = (item) => {
		this.props.history.push(`/admin/integrationDetail/${item.id}`);
	}

	render() {
		const { t, classes } = this.props;
		const { itemsPerPage, usersTotalCount, page, userTable, inputName, inputEmail, inputLogin, inputStateProvince, inputStatus } = this.state;

		return (
			<CommonPage bottomAppBar={
				<CustomPagination
					pageSize={itemsPerPage}
					recordCount={usersTotalCount}
					offset={itemsPerPage * (page - 1)}
					clickEvent={(e, offset) => this.handleSearch(Math.round(offset / itemsPerPage) + 1)}
				/>}>
				{this.state.alert}
				<DocumentTitle title={'Connectyse - ' + t("MENU_REGISTER")} />
				<GridContainer>
					<GridItem xs={12}>
						<Heading
							textAlign="left"
							title={t("MENU_REGISTER")}
						/>
						<Breadcrumbs items={this.state.breadcrumbItems} />
						<Card>
							<CardBody>
								<GridContainer style={{ marginBottom: '40px' }}>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<h4><b>Filtrar por:</b></h4>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<CustomInput
												id="inputName"
												name="inputName"
												labelText="Nome"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													value: inputName,
													onChange: this.handleChange.bind(this, "inputName"),
													type: "text"
												}}
											/>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<CustomInput
												id="inputEmail"
												name="inputEmail"
												labelText="Email"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													value: inputEmail,
													onChange: this.handleChange.bind(this, "inputEmail"),
													type: "text"
												}}
											/>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.formControl}
										>
											<CustomInput
												id="inputLogin"
												name="inputLogin"
												labelText="Cpf"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													value: inputLogin,
													onChange: this.handleChange.bind(this, "inputLogin"),
													type: "text"
												}}
											/>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Estado
									</InputLabel>
											<Select
												id="inputStateProvince"
												name="inputStateProvince"
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												inputProps={{
													value: inputStateProvince,
													onChange: this.handleChange.bind(this, "inputStateProvince"),
												}}
											>
												{stateProvinces && Object.keys(stateProvinces).map(key =>
													<MenuItem
														key={key}
														id={key}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={key}
													>{stateProvinces[key]}
													</MenuItem>)
												}

											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Status
									</InputLabel>
											<Select
												id="inputStatus"
												name="inputStatus"
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												inputProps={{
													value: inputStatus,
													onChange: this.handleChange.bind(this, "inputStatus"),
												}}
											>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value={'true'}
												>Ativo
										</MenuItem>
												<MenuItem
													classes={{
														root: classes.selectMenuItem,
														selected: classes.selectMenuItemSelected
													}}
													value={'false'}
												>Inativo
										</MenuItem>
												}

								</Select>
										</FormControl>
									</GridItem>
									{/* BUTTON*/}
									<GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
										<Button size="sm" color="primary" onClick={this.handleFilter.bind(this)} >Filtrar</Button>
										<Button size="sm" color="whiteButtonColor" onClick={this.handleClearFilter.bind(this)} >Limpar</Button>
									</GridItem>
								</GridContainer>


								{/* TABLE */}
								{userTable.length > 0 &&
									<Fragment>
										<Table
											hover
											tableHead={["Código Cliente", "Cpf", "Nome", "Email", "Estado", "Atualização", "Ações"]}
											tableData={userTable.map((item) => ([
												<div style={{ display: 'flex' }}>
													<div className={styles.circle} style={{ backgroundColor: item.active ? item.isOnline ? 'green' : 'blue' : 'red', alignSelf: 'center' }}></div>
													<div>{_.get(item, 'businessPartners[0].businessPartner.codigoCliente')}</div>
												</div>,
												item.userName.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4"),
												item.firstName,
												_.toLower(item.email),
												item.province,
												moment(item.updatedAt).format("L"),
												<div style={{ textAlign: 'left' }}>
													<span className={cx('icon-visualizar_dados', classes.actionButton)} title="Visualizar dados" onClick={this.handleShowUserData.bind(this, item)}></span>
													<span className={cx('icon-detalhar', classes.actionButton)} title="Detalhes" onClick={this.handleShowDetails.bind(this, item)}></span>
													<span className={cx('icon-redefinir_senha', classes.actionButton)} title="Redefinir senha" onClick={this.handleChangePWD.bind(this, item)}></span>
												</div>
											]))}

											customCellClasses={[classes.left, classes.center, classes.center, classes.right]}
											// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
											customClassesForCells={[0, 4, 5, 6]}
											customHeadCellClasses={[classes.left, classes.center, classes.center]}
											// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
											customHeadClassesForCells={[0, 4, 5]}
										/>

									</Fragment>
								}

								{usersTotalCount === 0 &&
									<GridContainer>
										<GridItem>
											Nenhum item encontrado. Mude o filtro e tente novamente.
							</GridItem>
									</GridContainer>}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}

	// PAGINATION
	handleSearch = (page) => {
		this.props.ShowLoader(<Loader />);
		this.props.setURLState({ page });
	}

	handleClearFilter = () => {
		this.props.ShowLoader(<Loader />);
		this.setState({
			filter: "",
			inputCodigo: "",
			inputName: "",
			inputEmail: "",
			inputLogin: "",
			inputStateProvince: "",
			inputStatus: "",
			isOnline: "",
			page: 1
		}, () => {
			this.handleFilter();
		});
	}

	handleFilter = () => {
		const { inputCodigo, inputName, inputEmail, inputLogin, inputStateProvince, inputStatus, isOnline } = this.state;

		this.props.setURLState({ inputCodigo, inputName, inputEmail, inputLogin, inputStateProvince, inputStatus, isOnline, page: 1 })
	}

	handleStatus = (active) => {
		var circle;
		if (!active) {
			circle = <div className={styles.circle} style={{ backgroundColor: "red", alignSelf: 'center' }}></div>
		} else {
			circle = <div className={styles.circle} style={{ backgroundColor: "green", alignSelf: 'center' }}></div>
		}
		return circle;
	}

	stringifyFilter = (filterData) => {

		const { inputCodigo, inputName, inputEmail, inputLogin, inputStateProvince, inputStatus, isOnline } = filterData;

		if (inputName.length === 0 && inputEmail.length === 0 && inputLogin.length === 0 && inputStateProvince.length === 0 && inputStatus.length === 0 && isOnline.length === 0) {
			return false;
		}
		//
		this.props.ShowLoader(<Loader />);
		let filter = "?$filter=";
		let fCodigo = "";
		let fNome = "";
		let fEmail = "";
		let fCpf = "";
		let fStateProvince = "";
		let fStatus = "";
		let fOnline = "";

		// CÓDIGO
		fCodigo = inputCodigo ? "substringof('" + inputCodigo + "', Output) and " : "";

		// NOME
		fNome += inputName ? "substringof('" + inputName + "', FirstName) and " : "";

		// EMAIL
		fEmail += inputEmail ? "substringof('" + inputEmail + "', Email) and " : "";

		// CPF
		let cpf = inputLogin.indexOf(".") > -1 ? _.replace(inputLogin, /\D/g, '') : inputLogin;
		fCpf += inputLogin ? "substringof('" + cpf + "', UserName) and " : "";

		// STATE PROVINCE
		fStateProvince += inputStateProvince ? "Address/StateProvince eq '" + inputStateProvince + "' and " : "";

		// STATUS
		fStatus += inputStatus ? "Active eq " + inputStatus + " and " : "";

		// ONLINE
		fOnline += isOnline ? "Insights/Active eq " + isOnline + " and " : "";
		//
		filter += fCodigo + fNome + fEmail + fCpf + fStateProvince + fStatus + fOnline;
		filter = filter.substring(0, filter.lastIndexOf(" and "));
		return filter;
	}
}

User.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		lsUsers: state.user.lsUsers,
		usersDataFailed: state.user.usersDataFailed,
		userPwd: state.user.userPwd,
		userPwdFailed: state.user.userPwdFailed,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetUsers, GetDataTypes, ChangePassword, GetBusinessPartnerDataTypes, ShowLoader, HideLoader }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({ inputCodigo: '', inputName: '', inputEmail: '', inputLogin: '', inputStateProvince: '', inputStatus: '', isOnline: '', page: 1 }),
	withStyles(sweetAlertStyle),
	withTranslation(),
)(User);