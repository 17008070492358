import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    userPwd: false,
    userPwdFailed: false,
    lsUsers: {},
    businessPartnerDataTypesDataFailed: null,
    lsBusinessPartnerDataTypes: []
};

const fetchUserData = (state, action) => {
    return updateObject(state, {
        lsUsers: action.lsUsers
    });
};

const fetcBusinessPartnerDataTypesData = (state, action) => {
    return updateObject(state, {
        lsBusinessPartnerDataTypes: action.businessPartnerDataTypes
    });
};

const fetchChangePassword = (state, action) => {
    return updateObject(state, {
        userPwd: action.userPwd
    });
};

// DATA FAIL
const fetchUserFailed = (state, action) => {
    return updateObject(state, {
        usersDataFailed: true
    });
};

const fetcBusinessPartnerDataTypesFailed = (state, action) => {
    return updateObject(state, {
        businessPartnerDataTypesDataFailed: action.businessPartnerDataTypesDataFailed
    });
};

const fetchChangePasswordFailed = (state, action) => {
    return updateObject(state, {
        userPwdFailed: true
    });
};

//
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_DATA:
            return fetchUserData(state, action);

        case actionTypes.FETCH_BUSINESS_PARTNER_DATA_TYPE:
            return fetcBusinessPartnerDataTypesData(state, action);

        case actionTypes.FETCH_BUSINESS_PARTNER_DATA_TYPE_FAILED:
            return fetcBusinessPartnerDataTypesFailed(state, action);

        case actionTypes.FETCH_CHANGE_PASSWORD_DATA:
            return fetchChangePassword(state, action);

        case actionTypes.FETCH_USER_FAILED:
            return fetchUserFailed(state, action);

        case actionTypes.FETCH_CHANGE_PASSWORD_FAILED:
            return fetchChangePasswordFailed(state, action);

        default:
            return state;
    }
};

export default userReducer;