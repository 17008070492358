import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

let systemConfig = JSON.parse(localStorage.getItem('config'));

const initialState = {
    customConfigData: systemConfig ? systemConfig : {},
    getCustomConfigFailed: false,
    updateCustomConfigFailed: false,
};

const fetchCustomizationData = (state, action) => {
    return updateObject(state, {
        customConfigData: action.customConfig
    });
};

const updateCustomizationData = (state, action) => {
    return updateObject(state, {
        customConfigData: action.postCustomConfig
    });
};


// DATA FAIL
const fetchCustomizationFailed = (state, action) => {
    return updateObject(state, {
        getCustomConfigFailed: action.getCustomConfigFailed
    });
};
const updateCustomizationFailed = (state, action) => {
    return updateObject(state, {
        updateCustomConfigFailed: action.updateCustomConfigFailed
    });
};

const systemConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMIZATION_DATA: return fetchCustomizationData(state, action);
        case actionTypes.POST_CUSTOMIZATION_DATA: return updateCustomizationData(state, action);

        case actionTypes.FETCH_CUSTOMIZATION_FAILED: return fetchCustomizationFailed(state, action);
        case actionTypes.POST_CUSTOMIZATION_FAILED: return updateCustomizationFailed(state, action);

        default: return state;
    }
};

export default systemConfigReducer;