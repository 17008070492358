import React, { useState } from "react";
import { makeStyles, Drawer, Typography, Divider } from "@material-ui/core";

const ShellContext = React.createContext({ drawerOpen: false });

const useStyles = makeStyles(theme => ({
    drawer: {
    },
    drawerPaper: {
        width: "260px",
        padding: "8px"
    },
    toolbar: theme.mixins.toolbar
}))

const ShellProvider = ({ children }) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const openDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);
    const toggleDrawer = () => setDrawerOpen(!drawerOpen);

    return (
        <ShellContext.Provider value={{ drawerOpen, openDrawer, closeDrawer, toggleDrawer }}>
            <>
                <Drawer
                    open={drawerOpen}
                    onClose={closeDrawer}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}>
                    <div className={classes.toolbar} />
                    <Typography variant="h6">APLICATIVOS</Typography>
                    <Divider/>
                </Drawer>
                {children}
            </>
        </ShellContext.Provider>
    )
}

export { ShellProvider, ShellContext }