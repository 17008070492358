import React, { Component } from 'react';
import Pagination from "material-ui-flat-pagination";

import { createMuiTheme, MuiThemeProvider, withStyles, Toolbar } from "@material-ui/core";

import customPaginationStyle from "assets/jss/material-dashboard-pro-react/views/customPaginationStyle";
import { customColors } from "assets/jss/material-dashboard-pro-react.jsx";

import compose from "utils/compose";

const pagTheme = createMuiTheme({
    overrides: {
        MuiFlatPagination: {
            root: {
                border: '1px solid ' + customColors[0],
                borderRadius: '20px',
                background: 'white'
            },
            colorInheritOther: {
                color: '#333333',
                fontWeight: '300',
                '&:hover': {
                    color: customColors[0],
                    fontWeight: '800',
                    background: 'none'
                }
            },
        },
        MuiFlatPageButton: {
            root: {
                lineHeight: '1',
            },
            text: {
                color: customColors[0]
            }
        }
    },
    palette: {
        primary: {
            main: '#000',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

class CustomPagination extends Component {
    render() {
        const { pageSize, recordCount, classes, offset, clickEvent } = this.props;
        return (
            <Toolbar className={classes.toolbar}>
                <MuiThemeProvider theme={pagTheme}>
                    <Pagination
                        otherPageColor="inherit"
                        currentPageColor="default"
                        limit={pageSize}
                        offset={offset}
                        total={recordCount}
                        onClick={clickEvent}
                    />
                    <span className={classes.totalCount}>Total: <b>{recordCount}</b></span>
                </MuiThemeProvider>
            </Toolbar>
        );
    }
}
export default compose(
    withStyles(customPaginationStyle),
)(CustomPagination);
