import React from "react";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from "components/CustomButtons/Button.jsx";

import styles from './BreadcrumbsNav.module.scss';

class BreadcrumbsNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //titleNavPill: "Regras Gerais",
            items: props.items
        }
    }
    render() {
        const { functionBack, buttonBack } = this.props;
        return (
            <div style={{ display: 'flex', paddingBottom: '15px' }}>
                <Breadcrumbs arial-label="Breadcrumb" className={styles.breadcrumb} style={{ marginTop: '15px' }}>
                    {this.state.items.map(i => (
                        (!i.last) ?
                            <div key={i}>
                                {i.name}
                            </div>
                            :
                            <Typography color="primary" key={i}>
                                {i.name}
                            </Typography>
                    ))}
                </Breadcrumbs>
                {buttonBack &&
                    <Button
                        style={{ float: 'right', position: 'absolute', right: '45px', marginTop: '0.7em' }}
                        color="greenBorderButtonColor"
                        size="sm"
                        onClick={functionBack}>Voltar</Button>
                }
            </div>
        );
    }
}

export default BreadcrumbsNav;
