import {
  dangerColor,
  successColor,
  defaultFont,
  whiteColor,
  grayColor,
  germiniDefaultColors,
  customColors
} from "assets/jss/material-dashboard-pro-react.jsx";

const customInputStyle = {
  disabled: {
    "&:before": {
      borderBottom: "none !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: germiniDefaultColors[3] + "!important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: customColors[0]
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0]
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: customColors[0]
    }
  },
  blueUnderline: {
    "&:after": {
      borderColor: successColor[0]
    }
  },
  labelCustom: {
    fontSize: "10px",
    color: "red",
    alignSelf: "flex-end"
  },
  labelRoot: {
    ...defaultFont,
    color: germiniDefaultColors[5] + " !important",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "1.42857",
    top: "10px",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14]
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: whiteColor
    },
    "&:after": {
      backgroundColor: whiteColor
    }
  },
  input: {
    color: germiniDefaultColors[5],
    "&,&::placeholder": {
      fontSize: "15px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: grayColor[3]
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: whiteColor,
      opacity: "1"
    }
  }
};

export default customInputStyle;
