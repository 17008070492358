import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsIntegration: [],
    //lsDataTypes: [],
    integrationByIdData: {},
    integrationBlobData: {},
    integrationBlobFailed: false,
    integrationDataFailed: false,
    //dataTypesFaield: false,
    integrationByIdFailed: false,
};

const fetchIntegrationData = (state, action) => {
    return updateObject(state, {
        lsIntegration: action.lsIntegration
    });
};

const fetchIntegrationByIdData = (state, action) => {
    return updateObject(state, {
        integrationByIdData: action.integrationByIdData
    });
};

const fetchDataTypes = (state, action) => {
    return updateObject(state, {
        lsDataTypes: action.lsDataTypes
    });
}

const fetchBlobData = (state, action) => {
    return updateObject(state, {
        integrationBlobData: action.integrationBlobData
    });
}
// DATA FAIL
const fetchIntegrationFailed = (state, action) => {
    return updateObject(state, {
        integrationDataFailed: true
    });
};

const fetchDataTypeFailed = (state, action) => {
    return updateObject(state, {
        dataTypesFaield: true
    });
};

const fetchBlobFailed = (state, action) => {
    return updateObject(state, {
        integrationBlobFailed: true
    });
};

const fetchIntegrationByIdFailed = (state, action) => {
    return updateObject(state, {
        integrationByIdFailed: true
    });
};

//
const integrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INTEGRATION_DATA:
            return fetchIntegrationData(state, action);

        case actionTypes.FETCH_INTEGRATION_FAILED:
            return fetchIntegrationFailed(state, action);

        case actionTypes.FETCH_DATA_TYPES:
            return fetchDataTypes(state, action);

        case actionTypes.FETCH_DATA_TYPES_FAILED:
            return fetchDataTypeFailed(state, action);

        case actionTypes.FETCH_INTEGRATION_BLOB_DATA:
            return fetchBlobData(state, action);

        case actionTypes.FETCH_INTEGRATION_BLOB_FAILED:
            return fetchBlobFailed(state, action);
        
        case actionTypes.FETCH_INTEGRATION_BY_ID_DATA:
            return fetchIntegrationByIdData(state, action);

        case actionTypes.FETCH_INTEGRATION_BY_ID_FAILED:
            return fetchIntegrationByIdFailed(state, action);
        default:
            return state;
    }
};

export default integrationReducer;