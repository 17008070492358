import axios from 'axios';

// GET api/ProgramRules/Regulation
export const ImageUpload = (obj) => {
    const formData = new FormData();
    formData.append("File", obj);
    debugger;
    return axios.post(`core/api/Test/UploadFile`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data);
}
// PUT api/PreRegister/VerifyCpf?cpf=:CPF hotfix:add in dev
export const GetThumbnails = () =>
    axios.get(`/Images/thumbnails`)
        .then(response => response.data);
