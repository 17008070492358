import axios from 'axios';

// GET
export const GetMessages = (_filter, _page, _itemsPerPage) => {
    let page = '';
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }
    return axios.get(`/core/api/Messages/GetAll${_filter}${page}`).then(response => response.data);
}

// GET BY ID
export const GetMessageById = (id) => {
    let filter = `?$filter=Id eq '${id}'`
    return axios.get(`/core/api/Messages/GetAll${filter}`).then(response => response.data.items[0]);
}

// GET DRAFTS
export const GetDraftMessages = (_filter, _page, _itemsPerPage) => {
    let page = '';
    let count = (_page - 1) * _itemsPerPage;
    let filter = `?$filter=Visible eq false`;
    if (_filter) filter += _filter;
    page = `&$skip=${count}&$top=${_itemsPerPage}`;

    return axios.get(`/core/api/Messages/GetAll${filter}${page}`).then(response => response.data);
}

// POST
export const PostMessage = (draftId) => {
    return axios.post(`/core/api/Messages/CreateFromDraft?draftId=${draftId}`)
        .then(response => response.data);
}

export const DeleteMessage = (id) => {
    return axios.post(`/core/api/Messages/Delete?Id=${id}`)
        .then(response => response.data);
}