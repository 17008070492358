import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    lsMessagesData: [],
    messageData: [],
    deleteMessage: {},
    messagesDataFailed: false,
    messagesDataPost: false,
    messageDataPostFail: false,
    messageFormData: {
        messsage: {},
        isSubmitted: false,
        isDraft: true,
    },
};

const fetchMessagesData = (state, action) => {
    return updateObject(state, {
        lsMessagesData: action.lsMessagesData
    });
};

// DATA FAIL
const fetchMessageDataFailed = (state, action) => {
    return updateObject(state, {
        messagesDataFailed: true
    });
};

//POST 
const postMessageData = (state, action) => {
    return updateObject(state, {
        messagesDataPost: action.messagesDataPost
    });
};

const deleteMessageData = (state, action) => {
    return updateObject(state, {
        deleteMessage: action.deleteMessage
    });
};

const fetchMessageById = (state, action) => {
    return updateObject(state, {
        messageData: action.messageData
    });
};

//POST FAIL 
const postMessageDataFail = (state, action) => {
    return updateObject(state, {
        messageDataPostFail: true
    });
};

//
const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MESSAGE_FORM_DATA:
            return { ...state, messageFormData: action.messageFormData };
        case actionTypes.FETCH_MESSAGE_DATA:
            return fetchMessagesData(state, action);
        case actionTypes.POST_MESSAGE_DATA:
            return postMessageData(state, action);
        case actionTypes.DELETE_MESSAGE_DATA:
            return deleteMessageData(state, action);
        case actionTypes.POST_MESSAGE_DATA_FAIL:
            return postMessageDataFail(state, action);
        case actionTypes.FETCH_MESSAGE_FAILED:
            return fetchMessageDataFailed(state, action);
        case actionTypes.FETCH_MESSAGE_DATA_BY_ID:
            return fetchMessageById(state, action);
        default:
            return state;
    }
};

export default messagesReducer;