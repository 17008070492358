import * as actionTypes from '../../actionsTypes';
import * as API from '../../../api/app_admin/dataType/dataType';

export const fetchAppDataType = (data) => {
    return {
        type: actionTypes.FETCH_APP_DATA_TYPE_DATA,
        appDataTypeData: data
    };
};

export const fetchDataTypes = (data) => {
    return {
        type: actionTypes.FETCH_DATA_TYPES,
        lsDataTypes: data
    }
}

export const fetchDataTypeById = (data) => {
    return {
        type: actionTypes.FETCH_DATA_TYPE_BY_ID,
        dataTypeById: data
    };
};

export const postAppDataTypeData = (data) => {
    return {
        type: actionTypes.POST_APP_DATA_TYPE_DATA,
        appDataTypePost: data
    };
}

export const updateDataTypeData = (data) => {
    return {
        type: actionTypes.UPDATE_DATA_TYPE_DATA,
        updateDataType: data
    }
}

// FAIL FETCH -------------------------------------

export const fetchAppDataTypeFailed = (error) => {
    return {
        type: actionTypes.FETCH_APP_DATA_TYPE_FAILED,
        appDataTypeDataFailed: error
    };
};

export const updateDataTypeFailed = (error) => {
    return {
        type: actionTypes.UPDATE_DATA_TYPE_FAILED,
        updateDataTypeFailed: error
    };
};

export const fetchDataTypesFailed = (error) => {
    return {
        type: actionTypes.FETCH_DATA_TYPES_FAILED,
        dataTypesFailed: error
    };
};
//----------------------------------------------------

export const GetAppDataType = () => {
    return dispatch => {
        return API.GetAppDataType().then(response => {
            dispatch(fetchAppDataType(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchAppDataTypeFailed(error));
        });
    }
}

export const GetDataTypes = () => {
    return dispatch => (
        API.GetDataTypes().then(response => {
            dispatch(fetchDataTypes(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchDataTypesFailed(error));
            //dispatch(Loader.hideLoader());
        })
    );
}
export const GetDataTypeById = (id) => {
    return dispatch => {
        return API.GetDataTypeById(id).then(resp => {
            dispatch(fetchAppDataType(resp))
        }).catch(err => {
            console.log(err)
            dispatch(fetchAppDataTypeFailed(err))
        })

    }
}

export const PostAppDataType = (data) => {
    return dispatch => (
        API.PostAppDataType(data).then(response => {
            dispatch(postAppDataTypeData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchAppDataTypeFailed());
        })
    );
}

export const UpdateDataType = (data) => {
    return dispatch => {
        API.UpdateDataType(data).then(resp => {
            dispatch(updateDataTypeData(resp));
            return resp;
        }).catch(err => {
            console.log(err);
            dispatch(updateDataTypeFailed());
        })
    }
}
