import axios from 'axios';

// GET
export const GetAppDataType = () =>
    axios.get(`/app/api/AppConfigurations/GetReportMenus`)
        .then(response => response.data);

export const GetDataTypeById = (id) =>
    axios.get(`/core/api/Types/GetAll?$filter=Id eq '${id}'`)
        .then(response => response.data.items[0]);

export const GetDataTypes = () =>
    axios.get(`/core/api/Types/GetAll`)
        .then(response => response.data);
// POST
export const PostAppDataType = (obj) =>
    axios.post(`/app/api/AppConfigurations/SetReportMenus`, obj)
        .then(response => response.data);

export const UpdateDataType = (obj) =>
    axios.post(`/core/api/Types/Upsert`, obj)
        .then(response => response.data);