import * as actionTypes from './actionsTypes';
import * as API from '../api/systemConfig';

export const fetchCustomizationData = (data) => {
    return {
        type: actionTypes.FETCH_CUSTOMIZATION_DATA,
        customConfig: data
    };
};

export const postCustomizationDat = (data) => {
    return {
        type: actionTypes.POST_CUSTOMIZATION_DATA,
        postCustomConfig: data
    };
}

// FAIL  -------------------------------------

export const fetchCustomizationFailed = (error) => {
    return {
        type: actionTypes.FETCH_CUSTOMIZATION_FAILED,
        getCustomConfigFailed: error
    };
};

export const updateCustomizationFailed = (error) => {
    return {
        type: actionTypes.POST_CUSTOMIZATION_FAILED,
        updateCustomConfigFailed: error
    };
};

//----------------------------------------------------
export const GetCustomizationData = () => {
    //debugger;
    return dispatch => (
        API.GetCustomizationData().then(response => {
            dispatch(fetchCustomizationData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchCustomizationFailed(error));
        })
    );
};

export const PutCustomizationData = (obj) => {
    return dispatch => (
        API.UpdateCustomizationData(obj).then(response => {
            dispatch(fetchCustomizationData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(updateCustomizationFailed(error));
        })

    );
};
