import * as actionTypes from '../../../actions/actionsTypes';
import {
    updateObject
} from '../../../utility';

const initialState = {
    appDataTypeData: [],
    updateDataType: {},
    lsDataTypes: [],
    appDataTypeDataFailed: false,
    appDataTypePost: false,
    dataTypesFailed: false,
    updateDataTypeFailed: false,
};

const fetchAppDataTypeData = (state, action) => {
    return updateObject(state, {
        appDataTypeData: action.appDataTypeData
    });
};

const fetchDataTypes = (state, action) => {
    return updateObject(state, {
        lsDataTypes: action.lsDataTypes
    });
}

//POST 
const postAppDataTypeData = (state, action) => {
    return updateObject(state, {
        appDataTypePost: action.appDataTypePost
    });
};

const updateDataTypeData = (state,action) => {
    return updateObject(state, {
        updateDataType: action.updateDataType
    })
}
// DATA FAIL
const fetchAppDataTypeFailed = (state, action) => {
    return updateObject(state, {
        appDataTypeDataFailed: true
    });
};

const fetchDataTypeFailed = (state, action) => {
    return updateObject(state, {
        dataTypesFailed: true
    });
};

const updateDataTypeFailed = (state,action) => {
    return updateObject(state, {
        updateDataTypeFailed: true
    })
}

//
const appDataTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_APP_DATA_TYPE_DATA:
            return fetchAppDataTypeData(state, action);
        case actionTypes.POST_APP_DATA_TYPE_DATA:
            return postAppDataTypeData(state, action);
        case actionTypes.UPDATE_DATA_TYPE_DATA:
            return updateDataTypeData(state, action);
        case actionTypes.FETCH_APP_DATA_TYPE_FAILED:
            return fetchAppDataTypeFailed(state, action);
        case actionTypes.UPDATE_DATA_TYPE_FAILED: 
            return updateDataTypeFailed(state, action);    
        case actionTypes.FETCH_DATA_TYPES:
            return fetchDataTypes(state, action);
        case actionTypes.FETCH_DATA_TYPES_FAILED:
            return fetchDataTypeFailed(state, action);
        default:
            return state;
    }
};

export default appDataTypeReducer;