import * as actionTypes from './actionsTypes';
import * as API from '../api/dashboard';

export const fetchDashboardData = (data) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_DATA,
        lsDashData: data
    };
};

export const fetchChartsData = (data) => {
    return {
        type: actionTypes.FETCH_CHARTS_DATA,
        lsChartsData: data
    };
};

export const fetchAnnouncementData = (data) => {
    return {
        type: actionTypes.FETCH_ANNOUNCEMENT_DATA,
        lsAnnouncementData: data
    };
};

// FAIL FETCH -------------------------------------

export const fetcDashboardFailed = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_FAILED,
        dashDataFailed: error
    };
};
export const fetchChartsFailed = (error) => {
    return {
        type: actionTypes.FETCH_CHARTS_FAILED,
        chartDataFailed: error
    };
};
export const fetchAnnouncementFailed = (error) => {
    return {
        type: actionTypes.FETCH_ANNOUNCEMENT_FAILED,
        announcementDataFailed: error
    };
};

//----------------------------------------------------
export const GetDashboardData = () => {
    return dispatch => (
        API.GetDashboardData().then(response => {
            dispatch(fetchDashboardData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetcDashboardFailed());
        })
    );
};

export const GetChartsData = () => {
    return dispatch => (
        API.GetCharts().then(response => {
            dispatch(fetchChartsData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchChartsFailed());
        })
    );
}

export const GetAnnouncementData = () => {
    return dispatch => (
        API.GetAnnouncementData().then(response => {
            dispatch(fetchAnnouncementData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchAnnouncementFailed());
        })
    );
}