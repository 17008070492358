import * as actionTypes from 'redux/actions/actionsTypes';
import * as API from 'redux/api/user/user';
import * as Loader from '../loader';

export const fetchUser = (data) => {
    return {
        type: actionTypes.FETCH_USER_DATA,
        lsUsers: data
    };
}
    ;
export const fetchBusinessPartnerDataTypes = (data) => {
    return {
        type: actionTypes.FETCH_BUSINESS_PARTNER_DATA_TYPE,
        businessPartnerDataTypes: data
    };
};

export const fetchChangePassword = (data) => {
    return {
        type: actionTypes.FETCH_CHANGE_PASSWORD_DATA,
        userPwd: data
    }
}

// FAIL FETCH -------------------------------------

export const fetchUserFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_FAILED,
        usersDataFailed: error
    };
};

export const fetchBusinessPartnerDataTypesFailed = (error) => {
    return {
        type: actionTypes.FETCH_BUSINESS_PARTNER_DATA_TYPE_FAILED,
        businessPartnerDataTypesDataFailed: error
    };
};

export const fetchChangePasswordFailed = (error) => {
    return {
        type: actionTypes.FETCH_CHANGE_PASSWORD_FAILED,
        userPwdFailed: error
    };
}

//----------------------------------------------------

export const GetBusinessPartnerDataTypes = (businessPartnerId) => {
    return dispatch => (
        API.GetBusinessPartnerDataTypes(businessPartnerId).then(response => {
            dispatch(fetchBusinessPartnerDataTypes(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchBusinessPartnerDataTypesFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
}
    ;
export const GetUsers = (_filter, _page, _itemsPerPage) => {
    return dispatch => (
        API.GetUsersData(_filter, _page, _itemsPerPage).then(response => {
            dispatch(fetchUser(response));
            //console.log(_filter)
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchUserFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};

export const ChangePassword = (userName) => {
    return dispatch => (
        API.ChangePassword(userName).then(response => {
            dispatch(fetchChangePassword(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchChangePasswordFailed(error));
            dispatch(Loader.hideLoader());
        })
    );
};