import axios from 'axios';

// GET
export const GetUsersData = (_filter, _page, _itemsPerPage) => {
    let page = "";
    let count = (_page - 1) * _itemsPerPage;
    if (_filter && _page) {
        page = `&$skip=${count}&$top=${_itemsPerPage}`
    } else if (!_filter && _page) {
        page = `?$skip=${count}&$top=${_itemsPerPage}`
    }
    //
    const url = `${process.env.REACT_APP_CONNECTYSE_API_URL}core/api/Users/GetAll${_filter}${page}`;
    return axios.get(url).then(response => response.data);
}

export const GetBusinessPartnerDataTypes = (businessPartnerId) =>
    axios.get(`/core/api/Users/GetBusinessPartnerDataTypes`, {
        params: {
            businessPartnerId: businessPartnerId,
        }
    })
        .then(response => response.data)
        .catch(err => console.warn(err));

// CHANGE PASSWORD
export const ChangePassword = (userName) => {
    const url = `${process.env.REACT_APP_CONNECTYSE_API_URL}core/api/Users/ResetPassword?userName=${userName}`;
    return axios.post(url, {}).then(response => response.data);
}

