import React from 'react';

import Loader from "components/Loader/Loader.jsx";

import compose from "utils/compose";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import defaultImage from "assets/img/image_placeholder.jpg";
import { ShowLoader, HideLoader } from 'redux/actions';

class Thumb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            thumb: undefined,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.file) { return; }
        this.setState({ loading: true }, () => {
            if (nextProps.file instanceof File) {
                let reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({ loading: false, thumb: reader.result });
                };
                reader.readAsDataURL(nextProps.file);
            }
            else {
                this.setState({ loading: false, thumb: nextProps.file });
            }
        });
    }

    render() {
        const { loading, thumb } = this.state;
        const { width } = this.props;

        loading ? this.props.ShowLoader(<Loader />) : this.props.HideLoader();

        return (
            <div className={"thumbnail"}>
                <img src={thumb ? thumb : defaultImage} alt="..." width={width + 'px'} />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { ShowLoader, HideLoader }
    , dispatch);
export default compose(
    connect(null, mapDispatchToProps),
)(Thumb);