export const renderObj = (content, type) => {

    let arr = content.split('\n');
    const columns = arr[0].split(',');
    arr.splice(0, 1);

    const arrFinal = [];

    arr.map(x => {
        let arrayObj = x.split(',');
        let obj = {};
        if (type && type === 'cpf') {
            obj = handleCpfObj(arrayObj[0]);
            if (obj !== null) {
                arrFinal.push(obj)
            }
        } else {
            columns.map((key, index) => {
                obj[key] = arrayObj[index];
                arrFinal.push(obj);
            })
        }
    })
    return arrFinal;
}

function handleCpfObj(cpf) {
    const cpfObj = {};
    cpf = cpf.replace(/\t|\r/g, "");
    if (cpf !== '' && cpf !== '0') {
        cpfObj['cpf'] = cpf;
        cpfObj['isValid'] = validaCPF(cpf);
        cpfObj['active'] = false;
        return cpfObj;
    }
    else return null;
}


function validaCPF(cpf) {
    var nums, digs, sum, i, result, equalDigs;
    equalDigs = 1;
    if (cpf.length < 11)
        return false;
    for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
            equalDigs = 0;
            break;
        }
    if (!equalDigs) {
        nums = cpf.substring(0, 9);
        digs = cpf.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--)
            sum += nums.charAt(10 - i) * i;
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result != digs.charAt(0))
            return false;
        nums = cpf.substring(0, 10);
        sum = 0;
        for (i = 11; i > 1; i--)
            sum += nums.charAt(11 - i) * i;
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result != digs.charAt(1))
            return false;
        return true;
    }
    else
        return false;
}

