import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import Datetime from "react-datetime";
import Chart from 'react-apexcharts'

// react plugin for skeleton
import Skeleton from "react-loading-skeleton";
// @material-ui/core components
import { Icon, FormControl, InputLabel, Select, MenuItem, withStyles } from "@material-ui/core";

// core components
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DocumentTitle from 'react-document-title';
import Loader from "components/Loader/Loader.jsx";
import Heading from "components/Heading/Heading.jsx"
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx';

// Utils
import compose from "utils/compose";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import styles from './integration.module.scss';
import { customColors } from "assets/jss/material-dashboard-pro-react.jsx";

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetIntegration, GetDashboardData, GetDataTypes, ShowLoader, HideLoader } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';
import CommonPage from "../../components/Pages/CommonPage";
import { connectRouterState } from "utils/connectRouterState";

class Integration extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			lsIntegrationData: [],
			filter: '',
			integrationTable: [],
			statusData: [
				"Aborted",
				"Canceled",
				"Completed",
				"Enqueued",
				"Error",
				"Pending",
				"Running"
			],
			dashIntegrationGraph: null,
			filterDataString: "",
			filterDataType: "",
			filterStatusData: "",
			filterDate: null,
			pageSize: 20,
			page: 1,
			recordCount: 0,
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Integração", last: true },
			],
		};

		moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		this.props.GetDashboardData();
		this.props.GetDataTypes().then(() => {
			this.setState({ ...this.props.urlState });
			this.props.GetIntegration(this.stringifyFilter(this.props.urlState), _.get(this.props.urlState, 'page', ''), this.state.pageSize);
		})
		this.timer = setInterval(() => this.props.GetDashboardData(), 20000);
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState');
		let urlState = _.get(this.props, 'urlState');
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({ ...urlState });
			this.props.GetIntegration(this.stringifyFilter(urlState), urlState.page, this.state.pageSize);
		}

		if (prevProps.lsIntegration !== this.props.lsIntegration) {
			this.setState({
				lsIntegrationData: this.props.lsIntegration.items,
				recordCount: this.props.lsIntegration.count
			});
		}

		if (prevState.lsIntegrationData !== this.state.lsIntegrationData) {
			this.props.GetDataTypes().then(rest => {
				this.createTableObj(this.state.lsIntegrationData);
				this.props.HideLoader();
			});
		}

		if (prevProps.lsDataTypes !== this.props.lsDataTypes) {
			// console.log("lsDataTypes: ", this.props.lsDataTypes);
		}

		if (prevProps.integrationDataFailed !== this.props.integrationDataFailed) {
			// console.log("> integrationDataFailed: ", this.props.integrationDataFailed);
			this.props.HideLoader();
		}

		if (prevProps.dataTypesFailed !== this.props.dataTypesFailed) {
			this.props.HideLoader();

			console.log("dataTypesFailed: ", this.props.dataTypesFailed);
		}

		if (prevProps.lsDashData !== this.props.lsDashData) {
			this.setIntegrationData(this.props.lsDashData.integrations.status)
		}

	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	isJSON(str) {
		if (typeof (str) !== 'string') {
			return false;
		}
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * @author Ayrto 
	 * 
	 * @param data integration data from redux api call
	 * 
	 * Function to render the data of the cards on page
	 */
	setIntegrationData = (data) => {
		let statusTypes = this.state.statusData;
		let integrationsData = [];
		statusTypes.map(sT => {
			let sTFound = data.find(x => x.state === sT);
			let obj = {
				title: sT,
				total: sTFound ? sTFound.count : 0
			}
			integrationsData.push(obj)
		})

		this.setState({ integrationsData: integrationsData })
	}

	createTableObj = (data) => {
		let items = _.orderBy(_.map(data, item => {
			let output = null;
			if (this.isJSON(item.output)) {
				output = JSON.parse(item.output);
			}
			let obj = {
				id: item.id,
				businessDataTypeIcon: item.businessDataType.description.iconUri,
				businessDataType: _.find(this.props.lsDataTypes.items, ['id', item.businessDataTypeId]).description.title,
				service: item.service,
				createdDate: moment(item.createdAt).format("L"),
				createdHour: moment(item.createdAt).format('HH:mm'),
				status: _.capitalize(item.status),
				schema: item.businessDataType.schema,
				startedAtDate: output ? output.StartedAt != null ? moment(output.StartedAt).format("L") : null : "-",
				startedAtHour: output ? output.StartedAt != null ? moment(output.StartedAt).format('HH:mm') : null : "-",
				finishedAtDate: output ? output.FinishedAt != null ? moment(output.FinishedAt).format("L") : null : "-",
				finishedAtHour: output ? output.FinishedAt != null ? moment(output.FinishedAt).format('HH:mm') : null : "-",
				finished: output ? output.Finished === true ? "Sim" : "Não" : "-",
				integrationJobId: output ? output.IntegrationJobId ? output.IntegrationJobId : "" : "-",
				log: output ? output.Log[0] ? output.Log[0] : "" : "-",
				started: output ? output.Started === true ? "Sim" : "Não" : "-",
				succeed: output ? output.Succeed === true ? "Sim" : "Não" : "-",
				error: output ? output.Errors ? output.Errors.length > 0 ? true : false : false : false,
				infrastructureId: item.infrastructureId ? item.infrastructureId : "-"
			}
			return obj;
		}), ["businessDataType", "asc"]);

		this.setState({ integrationTable: _.without(items, undefined) });
	}

	handleChange = (event, v) => {
		const id = event.target ? event.target.id || event.target.name : event;
		let value = event.target ? event.target.value : v;
		//
		if (id === "filterDataString") {
			value = v.target.value;
		} else if (id === "filterDate") {
			value = moment(v).format("YYYY-MM-DD");
		}
		this.setState({ [id]: value });
	};
	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	createColumnInfo = (obj, index) => {
		switch (index) {
			case 0:
				return <div style={{ display: "flex" }} title={obj.service} >
					<span className={obj.businessDataTypeIcon} style={{ width: '50px', fontSize: '35px', color: customColors[0], textAlign: 'center' }} />
					<div style={{ margin: '10px 15px 0 0' }}>
						{obj.infrastructureId}
					</div>
					<div>
						<span>
							{obj.businessDataType}
						</span>
						<br />
						<span style={{ fontSize: '12px', fontStyle: 'italic' }}>
							{obj.schema}
						</span>
					</div>
				</div>
			case 2:
				return <div>
					<span>{obj.createdDate}</span>
					<br />
					<small>{obj.createdHour}</small>
				</div>
			case 3:
				let circle = "";
				if (obj.status === "Completed") {
					if (obj.error) {
						circle = <div className={styles.circle} style={{ backgroundColor: "orange", alignSelf: 'center' }}></div>
					} else {
						circle = <div className={styles.circle} style={{ backgroundColor: "green", alignSelf: 'center' }}></div>
					}
				} else if (obj.status === "Enqueued" || obj.status === "Pending") {
					circle = <div className={styles.circle} style={{ backgroundColor: "yellow", alignSelf: 'center' }}></div>
				} else if (obj.status === "Aborted" || obj.status === "Error" || obj.status === "Canceled") {
					circle = <div className={styles.circle} style={{ backgroundColor: "red", alignSelf: 'center' }}></div>
				} else if (obj.status === "Running") {
					circle = <div className={styles.circle} style={{ backgroundColor: "blue", alignSelf: 'center' }}></div>
				}

				return <div style={{ display: 'flex' }}>
					{circle}
				</div>
			case 4:
				return <div>
					<span>{obj.createdDate}</span>
					<br />
					<small>{obj.createdHour}</small>
				</div>
			case 5:
				return <div>
					<span>{obj.finishedAtDate}</span>
					<br />
					<small>{obj.finishedAtHour}</small>
				</div>
			default:
				return "";
		}
	}

	handleEdit = (item) => {
		this.props.history.push(`/admin/integrationDetail/${item.id}`);
	}

	handleSearch = (page) => {
		this.props.ShowLoader(<Loader />);
		this.props.setURLState({ page });
	}

	render() {
		const { t, classes, lsDataTypes } = this.props;
		const { integrationsData, integrationTable, statusData, filterDate, filterDataString, page, pageSize, recordCount } = this.state;

		return (
			<CommonPage bottomAppBar={
				<CustomPagination
					pageSize={pageSize}
					recordCount={recordCount}
					offset={pageSize * (page - 1)}
					clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
				/>}>
				<DocumentTitle title={'Connectyse - ' + t("MENU_INTEGRATION")} />
				<GridContainer>
					<GridItem xs={12}>
						<Heading
							textAlign="left"
							title={t("MENU_INTEGRATION")}
						/>
						<Breadcrumbs items={this.state.breadcrumbItems} />
					</GridItem>
					{integrationsData &&
						<>
							{integrationsData.map((item, i) => (

								<GridItem xs={12} sm={6} md={3} lg={3} key={i}>
									<Card>
										<CardHeader color="warning" stats icon>
											<div className={classes.miniCardTitle}>
												{t(item.title.toUpperCase())}
											</div>
										</CardHeader>
										<CardBody className={classes.cardBody}>
											<h4>{item.total}</h4>
										</CardBody>
									</Card>
								</GridItem>



							))}
							<GridItem xs={12} sm={6} md={3} lg={3}>
								<Card>
									<CardHeader color="warning" stats icon>
										<div className={classes.miniCardTitle}>
											Total
										</div>
									</CardHeader>
									<CardBody className={classes.cardBody}>
										<h4>{integrationsData.map(item => item.total).reduce((prev, next) => prev + next)}</h4>
									</CardBody>
								</Card>
							</GridItem>
						</>
					}
					<GridItem xs={12}>
						<Card>
							<CardBody>
								<GridContainer style={{ marginBottom: '40px' }}>
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<h4><b>Filtrar por:</b></h4>
									</GridItem>

									{/* FILTRO: TIPO DE DADOS */}
									<GridItem xs={12} sm={12} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Tipo de dado
                          						</InputLabel>
											<Select
												id="filterDataType"
												name="filterDataType"
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												value={this.state.filterDataType}
												inputProps={{
													onChange: this.handleChange
												}}
											>
												{lsDataTypes.items && _.map(_.orderBy(lsDataTypes.items, ['description.title', 'asc']), item =>
													<MenuItem
														key={item.id}
														id={item.id}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={item.description.title}
													>{item.description.title}
													</MenuItem>)
												}

											</Select>
										</FormControl>
									</GridItem>

									{/* FILTRO: STATUS */}
									<GridItem xs={12} sm={12} md={4} lg={4}>
										<FormControl
											fullWidth
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select"
												className={classes.selectLabel}
											>
												Status
                          						</InputLabel>
											<Select
												id="filterStatusData"
												name="filterStatusData"
												MenuProps={{
													className: classes.selectMenu
												}}
												classes={{
													select: classes.select
												}}
												value={this.state.filterStatusData}
												inputProps={{
													onChange: this.handleChange
												}}
											>
												{statusData && _.map(_.orderBy(statusData, ['description.title', 'asc']), item =>
													<MenuItem
														key={item}
														classes={{
															root: classes.selectMenuItem,
															selected: classes.selectMenuItemSelected
														}}
														value={item}
													>{t(item.toUpperCase())}
													</MenuItem>)
												}

											</Select>
										</FormControl>
									</GridItem>

									{/* 
										DATA
									*/}
									<GridItem xs={12} sm={12} md={4} lg={4}>
										<FormControl fullWidth >
											<Datetime
												id="filterDate"
												name="filterDate"
												timeFormat={false}
												onChange={this.handleChange.bind(this, "filterDate")}
												value={filterDate ? moment(filterDate).format("L") : ''}
												renderInput={(props) => {
													return (
														<CustomInput
															labelText={"Data criação "}
															formControlProps={{
																fullWidth: true
															}}
															inputProps={{ ...props }}

														/>
													);
												}}
											/>
										</FormControl>
									</GridItem>



									{/* 
										FILTRO POR STRING
									*/}
									<GridItem xs={12} sm={12} md={12} lg={12}>
										<CustomInput
											id="filterDataString"
											name="filterDataString"
											labelText="Pesquisar em log"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												value: filterDataString,
												onChange: this.handleChange.bind(this, "filterDataString"),
												type: "text"
											}}
										/>
									</GridItem>
									{/*	BUTTON */}
									<GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
										<Button size="sm" color="primary" onClick={this.handleFilter.bind(this)} >Filtrar</Button>
										<Button size="sm" color="whiteButtonColor" onClick={this.handleClearFilter.bind(this)} >Limpar</Button>
									</GridItem>
								</GridContainer>

								{/* TABLE */}
								{integrationTable.length > 0 &&
									<Table hover
										tableHead={["Nº do Processo/Tipo de dado", "Criado em", "Status", "Iniciado", "Finalizado", "Ações"]}
										tableData={integrationTable.map((item, index) => ([
											this.createColumnInfo(item, 0),
											this.createColumnInfo(item, 2),
											<div style={{ display: 'flex' }}>
												{this.createColumnInfo(item, 3)}
												<div style={{ display: 'inline-grid' }}>
													<span>{t(item.status.toUpperCase())}</span>
													{item.error &&
														<Fragment>
															<small>com erros</small>
														</Fragment>
													}
												</div>
											</div>,
											this.createColumnInfo(item, 4),
											item.finishedAtDate ? this.createColumnInfo(item, 5) : 'Pendente',
											<NavLink to={"integrationDetail/" + item.id}>
												<div style={{ textAlign: 'center' }} >
													<Icon style={{ cursor: 'pointer', background: customColors[0], fontSize: '24px', borderRadius: '50%', color: 'white' }} title="detalhes">more_horiz</Icon>
												</div>
											</NavLink>
										]))}

										customCellClasses={[classes.center, classes.right]}
										// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
										customClassesForCells={[0, 6]}
										customHeadCellClasses={[classes.center, classes.right]}
										// 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
										customHeadClassesForCells={[0, 6]}
									/>
								}

								{recordCount === 0 &&
									<GridContainer>
										<GridItem>
											Nenhum item encontrado. Mude o filtro e tente novamente.
										</GridItem>
									</GridContainer>}
							</CardBody>

						</Card>
					</GridItem>
				</GridContainer>
			</CommonPage>
		);
	}

	handleClearFilter = () => {
		this.setState({
			filterDataString: "",
			filterDataType: [],
			filterStatusData: "",
			filterDate: null,
			filter: "",
			page: 1,
		}, () => {
			this.handleFilter();
		});
	}

	handleFilter = () => {
		const { filterDataString, filterDataType, filterStatusData, filterDate } = this.state;

		this.props.setURLState({ filterDataString, filterDataType, filterStatusData, filterDate, page: 1 })
		// this.setState({ filter: filter });
		// this.setState({ currentPage: 1 });
		// this.props.GetIntegration(filter, 1, this.state.pageSize);
	}

	stringifyFilter = (filterData) => {
		const { filterDataString, filterDataType, filterStatusData, filterDate } = filterData;
		if (_.isEmpty(filterDataString) && _.isEmpty(filterDataType) && _.isEmpty(filterStatusData) && !filterDate) {
			return '';
		}
		//
		this.props.ShowLoader(<Loader />);
		let filter = "?&$filter=";
		let fString = "";
		let fType = "";
		let fDate = "";
		let fStatus = "";
		// texto
		fString = filterDataString ? "substringof('" + filterDataString + "', Output) and " : "";

		// TIPO DE DADO
		let typeId = _.get(_.find(this.props.lsDataTypes.items, ['description.title', filterDataType]), 'id', null);
		fType += typeId ? "BusinessDataTypeId eq '" + typeId + "' and " : "";

		// STATUS
		fStatus += filterStatusData ? "Status eq '" + filterStatusData + "' and " : "";

		// Data
		fDate += filterDate ? "day(CreatedAt) eq " + moment(filterDate).format('D') + " and month(CreatedAt) eq " + moment(filterDate).format('M') + " and year(CreatedAt) eq " + moment(filterDate).format('YYYY') + " and " : "";
		//
		filter += fString + fType + fStatus + fDate;
		filter = filter.substring(0, filter.lastIndexOf(" and "));
		return filter;
	}
}

Integration.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		lsIntegration: state.integration.lsIntegration,
		lsDataTypes: state.appDataType.lsDataTypes,
		lsDashData: state.dashboard.lsDashData,

		integrationDataFailed: state.integration.integrationDataFailed,
		dataTypesFailed: state.appDataType.dataTypesFailed
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetIntegration, GetDataTypes, GetDashboardData, ShowLoader, HideLoader }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({ filterDataString: '', filterDataType: "", filterStatusData: '', filterDate: null, page: 1 }),
	withStyles(dashboardStyle),
	withTranslation(),
)(Integration);