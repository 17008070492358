import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
// react plugin for tab title
import DocumentTitle from 'react-document-title';
// react plugin for creating stacked bar chart
import Chart from 'react-apexcharts'

// react plugin for skeleton
import Skeleton from "react-loading-skeleton";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomVectorMap from "components/CustomVectorMap/CustomVectorMap.jsx";


// Utils
import compose from "utils/compose";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import style from './dashboard.module.scss';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetDashboardData, ShowLoader, HideLoader } from '../../redux/actions';

// MOMENT JS
import * as moment from 'moment';

class Dash extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			cardsLoader: true,
			chatsLoader: true,
			announcementsLoader: true,
			dashGraphUsersData: null,
			dashGraphClientsData: [],
			cardSelesIndicators: null,
			cardRegistredUsers: null,
			cardActiveUsers: null,
			cardUseTime: null,
			usersOnline: null,
			mapData: null,
			mapUsersQtt: null,
			mapUserPercent: null,
			mapColor: null,
			states: null,
			dashVersionGraph: null,
			dashGraphUser: null,
			series: [],
		};
		moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
	}

	componentDidMount() {
		this.props.GetDashboardData();
		this.timer = setInterval(() => this.props.GetDashboardData(), 20000);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lsDashData !== this.props.lsDashData) {
			//console.log(this.props.lsDashData);
			this.setState({ cardRegistredUsers: this.props.lsDashData.users.total.registeredUsers });
			this.setState({ cardActiveUsers: this.props.lsDashData.users.total.activeUsers });
			this.setState({ cardUseTime: this.props.lsDashData.users.avaregePermanenceInSeconds })
			this.setState({ usersOnline: this.props.lsDashData.users.onlineUsers })
			this.setMapData(this.props.lsDashData)
			this.setUserChartData(this.props.lsDashData, 6);
			this.setAccessChartData(this.props.lsDashData, 6);
			this.setVersionChartData(this.props.lsDashData.users.byPlatformInfo);
		}
		if (prevProps.lsChartsData !== this.props.lsChartsData) {
			this.handleChartData(this.props.lsChartsData);
		}
		if (prevProps.lsAnnouncementData !== this.props.lsAnnouncementData) {
			//console.log("Dashboard - Announcement Data: ", this.props.lsAnnouncementData);
		}
		if (prevState.dashGraphUser !== this.state.dashGraphUser) {
			this.setState({ dashGraphUsersData: this.state.dashGraphUser });
		}
		//
		if (prevProps.cardsDataFailed !== this.props.cardsDataFailed) {
			console.log("Dashboard - cards Data Failed: ", this.props.cardsDataFailed);
		}
		if (prevProps.chartDataFailed !== this.props.chartDataFailed) {
			console.log("Dashboard - chart Data Failed: ", this.props.chartDataFailed);
		}

	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	handleChange = (event, value) => {
		this.setState({ value });
	};
	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	setMapData = (data) => {
		Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { el.style.display = 'none' });
		let qttObj = {};
		let percentObj = {};
		let colorObj = {};
		let arrayState = [];
		data.users.byStateUsers.map(item => {
			let key = item.condition.toLowerCase();
			let valueQtt = item.activeUsers
			qttObj[key] = valueQtt;
			percentObj[key] = Math.round((valueQtt / data.users.total.activeUsers) * 100);
			colorObj[key] = 1;
			return arrayState.push(key);
		})
		let objMapData = {
			usersQtt: qttObj,
			usersPercent: percentObj,
			colors: colorObj,
			states: arrayState
		};
		this.setState({ mapData: objMapData });
	};

	setVersionChartData = (data) => {
		let arraySeries = [];
		let arrayCat = _.map(data, 'platform');
		data.map(item => (
			item.byVersionUsers.map(i => {
				let obj = {
					platform: item.platform,
					version: i.condition,
					users: i.activeUsers
				}
				return arraySeries.push(obj);
			})
		));
		let allVersions = _.map(_.uniqBy(arraySeries, 'version'), 'version');
		let groupedList = _.mapValues(_.groupBy(arraySeries, 'platform'), clist => clist.map(car => _.omit(car, 'platform')));
		let arrayData = [];
		for (let index = 0; index < allVersions.length; index++) {
			var arrayTest = [];
			_.forEach(groupedList, function (value, key) {
				let val = _.find(value, ['version', allVersions[index]])
				if (_.find(value, ['version', allVersions[index]])) {
					let obj = {};
					obj[allVersions[index]] = val.users;
					return arrayTest.push(val.users)
				} else {
					let obj = {};
					obj[allVersions[index]] = 0;
					return arrayTest.push(0)
				}
			});
			var newObj = {
				name: this.props.t(allVersions[index]),
				data: arrayTest
			}
			arrayData.push(newObj);
		}
		let options = {
			chart: {
				stacked: true,
				//stackType: '100%',
				toolbar: {
					show: false
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories: arrayCat.map(i => this.props.t(i)),
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return val + " usuários"
					}
				}
			},
			fill: {
				opacity: 1
			},
			legend: {
				position: 'top',
				horizontalAlign: 'left',
				offsetX: 40
			}

		}
		let series = arrayData;
		this.setState({ dashVersionGraph: { options, series } })
		this.setState({ series: arrayData })
	}

	/**
	 * 	@author Ayrton Gomes
	 *  Function to set config of react-apexcharts users graph
	 *  @param data is the dataByMonthUsers
	 *  @param qtyMonth is the months quantity that graph must show
	 */

	setUserChartData = (data, qtyMonth) => {
		let labels = data.users.byMonthUsers.slice(0, qtyMonth).map(x => moment(x.condition).format('MMM/YY').toUpperCase()).reverse();
		let registereds = data.users.byMonthUsers.slice(0, qtyMonth).map(x => x.registeredUsers).reverse();
		let actives = data.users.byMonthUsers.slice(0, qtyMonth).map(x => x.activeUsers).reverse();

		const options = {
			chart: {
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false
				},
			},
			colors: ['#00549B', '#8D918B',],
			dataLabels: {
				enabled: false
			},
			stroke: {
				width: [4, 4],
				curve: 'straight',
				dashArray: [0, 4]
			},
			markers: {
				size: 5,
				hover: {
					sizeOffset: 5
				}
			},
			xaxis: {
				categories: labels,
			},
			grid: {
				borderColor: '#f1f1f1',
			},
			legend: {
				itemMargin: {
					horizontal: 20
				}
			}
		};
		const series = [
			{
				name: 'Ativos',
				type: 'line',
				data: actives
			},
			{
				name: 'Cadastrados',
				type: 'line',
				data: registereds

			},
		];

		this.setState({ dashGraphUser: { options, series } })
	}
	/**
	 * 	@author Ayrton Gomes
	 *  Function to set config of react-apexcharts users graph
	 *  @param data is an array of access per moth redux data returned from api call
	 *  @param qtyMonth is the months quantity that graph must show
	 */

	setAccessChartData = (data, qtyMonth) => {
		let labels = data.accessesPerMonth.slice(0, qtyMonth).map(x => moment(x.condition).format('MMM/YY').toUpperCase()).reverse();
		let accesses = data.accessesPerMonth.slice(0, qtyMonth).map(x => x.accesses).reverse();
		let usersCount = data.accessesPerMonth.slice(0, qtyMonth).map(x => x.usersDistinct).reverse();

		const options = {
			chart: {
				zoom: {
					enabled: false
				},
				toolbar: {
					show: false
				},
			},
			colors: ['#4caf50', '#f7a537'],
			dataLabels: {
				enabled: false
			},
			stroke: {
				width: [4, 4],
				curve: 'straight',
				dashArray: [0, 4]
			},
			markers: {
				size: 5,
				hover: {
					sizeOffset: 5
				}
			},
			xaxis: {
				categories: labels,
			},
			grid: {
				borderColor: '#f1f1f1',
			},
			legend: {
				itemMargin: {
					horizontal: 20
				}
			}
		};

		const series = [
			{
				name: 'Acessos',
				type: 'line',
				data: accesses
			},
			{
				name: 'Usuários que acessaram',
				type: 'line',
				data: usersCount

			},
		];

		this.setState({ dashAccessChart: { options, series } })
	}


	render() {
		const { t } = this.props;
		const { dashAccessChart, dashVersionGraph, mapData, dashGraphUsersData, cardRegistredUsers, cardActiveUsers, cardUseTime, usersOnline } = this.state;

		var graphLoaderSkeleton = (
			<div>
				<Skeleton height={300} count={1} />
				<p><Skeleton height={38} width={150} count={2} /></p>
			</div>
		);

		var cardNoContent = (
			<div className={style.cardNoContent}>
				<div>
					<span style={{ fontSize: '3rem' }} className={"icon-informacao"}></span>
				</div>
				<div>Sem informações para exibir nesse momento.</div>
			</div>
		);

		return (
			<div>
				<DocumentTitle title={'Connectyse - ' + t("MENU_DASHBOARD")} />
				<GridContainer style={{ paddingTop: '20px' }}>
					{/* card 1 */}
					<GridItem xs={12} sm={6} md={3} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color="warning">
									<Icon className={'icon-usuario_cadastrado'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{"CADASTROS"}
								</p>
								<h2 className={style.cardHeadContent}>{cardRegistredUsers}</h2>
							</CardHeader>
							<CardBody>
								<NavLink to="/admin/user">
									<div className={style.textCardBody}>
										<Icon>add_box</Icon>
										<span className={style.textSpan}>Ver mais</span>
									</div>
								</NavLink>
							</CardBody>
						</Card>
					</GridItem>
					{/* card 2 */}
					<GridItem xs={12} sm={6} md={3} lg={3}>
						<Card>
							<CardHeader color="success" stats icon>
								<CardIcon color="success">
									<Icon className={'icon-usuario_ativo'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{"ATIVOS/INATIVOS"}
								</p>
								<h2 className={style.cardHeadContent}>{cardActiveUsers}</h2>
							</CardHeader>
							<CardBody>
								<div className={style.textCardBody}>
									<NavLink className={style.textCardBody} to="/admin/user?inputStatus=true">
										<Icon>add_box</Icon>
										<span className={style.textSpan}>Ver mais</span>
									</NavLink>
									<NavLink className={style.textCardBody} to="/admin/user?inputStatus=false">
										<span className={style.textSpan + " " + style.usersOnline}><Icon style={{ fontSize: '1rem', margin: '1px 2px 0 0' }} className={'icon-usuario_inativo'}></Icon> Inativos: {cardRegistredUsers && cardActiveUsers ? (cardRegistredUsers - cardActiveUsers) : ''}</span>
									</NavLink>
								</div>
							</CardBody>
						</Card>
					</GridItem>
					{/* card 3 */}
					<GridItem xs={12} sm={6} md={3} lg={3}>
						<Card>
							<CardHeader color="green" stats icon>
								<CardIcon color="green">
									<Icon className={'icon-ico_online'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{"ONLINE"}
								</p>
								<h2 className={style.cardHeadContent}>{usersOnline}</h2>
							</CardHeader>
							<CardBody>
								<NavLink to="/admin/user?isOnline=true">
									<div className={style.textCardBody}>
										<Icon>add_box</Icon>
										<span className={style.textSpan}>Ver mais</span>
									</div>
								</NavLink>
							</CardBody>
						</Card>
					</GridItem>
					{/* card 4 */}
					<GridItem xs={12} sm={6} md={3} lg={3}>
						<Card>
							<CardHeader color="primary" stats icon>
								<CardIcon color="primary">
									<Icon className={'icon-permanenciaAPP'}></Icon>
								</CardIcon>
								<p className={style.cardHeadTitle}>
									{"PERMANÊNCIA"}
								</p>
								<h2 className={style.cardHeadContent}>{moment.utc(cardUseTime * 1000).format('mm:ss')}</h2>
							</CardHeader>
							<CardBody>
								<div className={style.textCardBody}>
									<Icon>update</Icon>
									<span className={style.textSpan}>min:seg</span>
								</div>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>

				{/* GRAPHS */}
				<GridContainer>
					{/* GRAPH 1 */}
					<GridItem xs={12} sm={12} md={6}>
						<Card>
							<CardHeader color="rose" stats icon>
								<CardIcon color="rose">
									<Icon>language</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{"USUÁRIOS POR ESTADO"}
								</div>
							</CardHeader>
							<CardBody>
								{mapData ?
									<CustomVectorMap
										mapColor={mapData.colors}
										mapUserPercent={mapData.usersPercent}
										mapUsersQtt={mapData.usersQtt}
										statesProvinces={mapData.states}
									/>
									: graphLoaderSkeleton}
							</CardBody>
						</Card>
					</GridItem>
					{/* GRAPH 2 */}
					<GridItem xs={12} sm={12} md={6}>
						<Card >
							<CardHeader color="primary" stats icon>
								<CardIcon color="primary">
									<Icon>bar_chart</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{"VERSIONAMENTO DO APP"}
								</div>
							</CardHeader>
							<CardBody>
								{dashVersionGraph ? dashVersionGraph.series.length > 0 ?
									<Chart options={dashVersionGraph.options} series={dashVersionGraph.series} type="bar" width={'100%'} height={405} />
									: cardNoContent : graphLoaderSkeleton}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
				{/* GRAPHS */}
				<GridContainer>
					{/* GRAPH 3 */}
					<GridItem xs={12} sm={12} md={6}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color="warning">
									<Icon>timeline</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{"USUÁRIOS"}
								</div>
							</CardHeader>
							<CardBody>
								{dashGraphUsersData ? dashGraphUsersData.series.length > 0 ?
									<Chart options={dashGraphUsersData.options} series={dashGraphUsersData.series} type="line" width={'98%'} height={405} />
									: cardNoContent : graphLoaderSkeleton}
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<Card>
							<CardHeader color="success" stats icon>
								<CardIcon color="success">
									<Icon>timeline</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{"ACESSOS"}
								</div>
							</CardHeader>
							<CardBody>
								{dashAccessChart ? dashAccessChart.series.length > 0 ?
									<Chart options={dashAccessChart.options} series={dashAccessChart.series} type="line" width={'100%'} height={405} />
									: cardNoContent : graphLoaderSkeleton}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div >
		);
	}
}

Dash.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {

	return {
		lsDashData: state.dashboard.lsDashData,
		dashDataFailed: state.dashboard.cardsDataFailed,
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetDashboardData, ShowLoader, HideLoader }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(dashboardStyle),
	withTranslation(),
)(Dash);