import React, { Fragment } from "react";
import * as _ from "lodash";
import { withTranslation } from 'react-i18next';
import MaterialTable, { MTableCell, MTableToolbar } from 'material-table';
import DocumentTitle from 'react-document-title';
import JSONTree from 'react-json-tree'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Paper } from '@material-ui/core';

// core components
import Divider from '@material-ui/core/Divider';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Loader from "components/Loader/Loader.jsx";
import Heading from "components/Heading/Heading.jsx"
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx';
import NavPills from "components/NavPills/NavPills.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import styles from '../integration.module.scss';
import { customColors } from "assets/jss/material-dashboard-pro-react.jsx";

import compose from "utils/compose";

import placeholder from "assets/img/placeholder.jpg";

// MOMENT JS
import * as moment from 'moment';

// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetIntegrationById, GetIntegrationBlobById, GetDataTypes, ShowLoader, HideLoader } from 'redux/actions';

class Detail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			itemDetail: undefined,
			breadcrumbItems: [
				{ name: "Home" },
				{ name: "Integração" },
				{ name: "Detalhamento de Integração", last: true },
			],
			jsonData: {},
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />);
		//if (this.props.lsIntegration.length === 0) {
		this.props.GetDataTypes().then(() => {
			const id = this.props.match && this.props.match.params.id;
			this.props.GetIntegrationById(id);
		});
		//} else {
		//const id = this.props.match && this.props.match.params.id;
		// let item = _.find(this.props.lsIntegration.items, ["id", id]);
		// this.setState({ itemDetail: item });
		// this.setOutputData(item.output);
		// this.props.HideLoader();
		//}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.integrationByIdData !== this.props.integrationByIdData) {
			//console.log(this.getBlobInfo(item.blob.uri));

			// const id = this.props.match && this.props.match.params.id;
			// let item = _.find(this.props.lsIntegration.items, ["id", id]);
			this.setState({ itemDetail: this.props.integrationByIdData });
			this.props.GetIntegrationBlobById(this.props.integrationByIdData.blobId)

		}
		if (prevState.itemDetail !== this.state.itemDetail) {
			this.setOutputData(this.state.itemDetail.output);
			this.props.HideLoader();
		}
		if (prevProps.integrationBlobData !== this.props.integrationBlobData) {
			this.setState({ jsonData: this.props.integrationBlobData });
			//this.props.HideLoader();
		}
	}


	isJSON(str) {
		if (typeof (str) !== 'string') {
			return false;
		}
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	}

	setOutputData = (output) => {
		if (this.isJSON(output)) {
			let outputData = JSON.parse(output);
			if (outputData.Log) {
				_.remove(outputData.Log, function (n) {
					return n.includes("Starting") || n.includes("Completed");
				})
				this.setState({ outputLog: outputData.Log })
				const arrayLog = [];
				outputData.Log.map(item => {
					return arrayLog.push({ log: item });

				});
				this.setState({ outputLogData: arrayLog })
			}
			if (outputData.Errors) {
				const arrayError = [];
				outputData.Errors.map(item => {
					return arrayError.push({ erro: item });
				});
				this.setState({ outputErrorData: arrayError })
			}
		}
	}

	handleCircle = (obj) => {
		let outputData = {};
		if (this.isJSON(obj.output)) {
			outputData = JSON.parse(obj.output);
		}
		let status = _.capitalize(obj.status);
		let circle = "";
		if (status === "Completed") {
			if (outputData.Errors && outputData.Errors.length > 0) {
				circle = <div className={styles.circle} style={{ backgroundColor: "orange", alignSelf: 'center' }}></div>
			} else {
				circle = <div className={styles.circle} style={{ backgroundColor: "green", alignSelf: 'center' }}></div>
			}
		} else if (status === "Enqueued" || status === "Pending") {
			circle = <div className={styles.circle} style={{ backgroundColor: "yellow", alignSelf: 'center' }}></div>
		} else if (status === "Aborted" || status === "Error" || status === "Canceled") {
			circle = <div className={styles.circle} style={{ backgroundColor: "red", alignSelf: 'center' }}></div>
		}

		return <div style={{ display: 'flex' }}>
			{circle}
		</div>
	}

	handleStatus = (obj) => {
		let outputData = {};
		if (this.isJSON(obj.output)) {
			outputData = JSON.parse(obj.output);
		}
		let status = _.capitalize(obj.status);
		if (status === "Completed") {
			if (outputData.Errors && outputData.Errors.length > 0) {
				status = "Completed_with_errors"
			}
		}
		return status.toUpperCase();
	}

	render() {
		const { classes, lsDataTypes, t } = this.props;
		const { itemDetail, jsonData } = this.state;
		return (
			<div>
				<DocumentTitle title={'Connectyse - Detalhamento de Integração'} />
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<Heading
							textAlign="left"
							title={t("MENU_INTEGRATION_DETAIL")}
						/>
						<Breadcrumbs items={this.state.breadcrumbItems} buttonBack functionBack={() => this.props.history.goBack()} />
						{itemDetail &&
							<Fragment>
								<Card>
									<CardBody style={{ display: 'flex' }}>
										<div style={{ textAlign: 'left', lineHeight: '35px' }}>
											<strong>ARQUIVO </strong> Id: {itemDetail.blobId} <br />
											Download: <a href={itemDetail.blob.uri} style={{ color: '#779344' }}>clique aqui</a>
										</div>
										<div style={{ display: 'flex', marginLeft: 'auto' }}>
											<div>
												<h6 className={classes.cardCategory} style={{ textAlign: 'right' }}>Tipo de dado</h6>
												<h4 className={classes.customCardTitle}>{_.find(lsDataTypes.items, ['id', itemDetail.businessDataTypeId]).description.title}</h4>
											</div>
											<div className={styles.iconCardBody}>
												{
													itemDetail.businessDataType.description.iconUri ?
														<span className={itemDetail.businessDataType.description.iconUri} style={{ fontSize: '70px', color: customColors[0] }} />
														:
														<img src={placeholder} alt="" />
												}
											</div>
										</div>
									</CardBody>

								</Card>
							</Fragment>
						}
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						{itemDetail &&
							<Card>
								<CardHeader>
									<GridContainer>
										<GridItem xs={12} sm={11} md={11}>
											<h4 className={classes.cardIconTitle}>
												<strong><b>Detalhamento - Log dos dados</b></strong>
											</h4>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>
									<GridContainer>
										<GridItem xs={12} sm={6} md={2}>
											Data e Hora
												<br />
											{moment(itemDetail.createdAt).format("L") + " " + moment(itemDetail.createdAt).format('LT')}
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											Status
												<br />
											<span style={{ display: 'flex' }}>{this.handleCircle(itemDetail)} {t(this.handleStatus(itemDetail))}</span>
										</GridItem>
										<GridItem xs={12} sm={6} md={3}>
											Nº do Processo
												<br />
											{itemDetail.infrastructureId}
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											ID
												<br />
											{itemDetail.id}
										</GridItem>
									</GridContainer>

									<Divider variant="middle" style={{ margin: '15px 0 ' }} />

									<NavPills
										color="primary"
										tabs={[
											{
												tabButton: "LOG DE DADOS",
												tabContent: (
													<GridContainer >

														<GridItem sm={12} xl={12} md={12}>
															{this.state.outputLogData && this.state.outputLogData.length > 0 ?
																<div style={{ whiteSpace: 'pre' }}>
																	<MaterialTable
																		components={{
																			Container: props => <Paper {...props} elevation={0} />,
																			Toolbar: props => <MTableToolbar {...props} classes={{ root: styles.tableTitle }} />,
																			Cell: props => <MTableCell {...props} style={{ padding: '14px 40px 12px 0', textAlign: 'left' }} />,
																		}}
																		localization={{
																			toolbar: {
																				searchPlaceholder: "Buscar"
																			},
																			pagination: {
																				labelDisplayedRows: '{from}-{to} de {count}',
																				labelRowsSelect: 'linhas'
																			}
																		}}
																		title="Integrações bem sucedidas"
																		columns={[
																			{ title: 'Logs', field: 'log', headerStyle: { paddingLeft: 0, textAlign: 'left' } },
																		]}
																		data={this.state.outputLogData}
																	/>
																</div>
																: 'Não foram gerados logs de integração'}
														</GridItem>

														<GridItem sm={12} xl={12} md={12} style={{ marginTop: '50px' }}>
															{this.state.outputErrorData && this.state.outputErrorData.length > 0 &&
																<div style={{ whiteSpace: 'pre' }}>
																	<MaterialTable
																		components={{
																			Container: props => <Paper {...props} elevation={0} />,
																			Toolbar: props => <MTableToolbar {...props} classes={{ root: styles.tableTitle }} />,
																			Cell: props => <MTableCell {...props} style={{ padding: '14px 40px 12px 0', textAlign: 'left' }} />,
																		}}
																		localization={{
																			toolbar: {
																				searchPlaceholder: "Buscar"
																			},
																			pagination: {
																				labelDisplayedRows: '{from}-{to} de {count}',
																				labelRowsSelect: 'linhas'
																			}
																		}}
																		title="Erros de integração"
																		columns={[
																			{ title: 'Erros', field: 'erro', headerStyle: { paddingLeft: 0, textAlign: 'left' } },
																		]}
																		data={this.state.outputErrorData}
																	/>
																</div>
															}
														</GridItem>
														<GridItem sm={12} xl={12} md={12}>
															{this.state.outputErrors &&
																<div style={{ whiteSpace: 'pre' }}>
																	<h3>Erros de integração</h3>
																	{this.state.outputErrors.length > 0 ?
																		this.state.outputErrors.map((item, index) => [
																			<p key={index}>{item}</p>
																		])
																		:
																		"* Não ocorreram erros"
																	}
																</div>
															}
														</GridItem>
													</GridContainer>

												)
											},
											{
												tabButton: "DADOS DO ARQUIVO",
												tabContent: (
													<GridContainer>
														<GridItem sm={12} md={12} lg={12}>
															{jsonData ?
																<div style={{ border: '1px solid #666', borderRadius: '3px', padding: '0 5px 3px 10px', background: 'rgb(255, 255, 250)' }}>
																	<JSONTree data={jsonData} hideRoot theme={{ base00: '#272822', }} />
																</div>
																: 'Não há dados a serem exibidos'
															}

														</GridItem>
													</GridContainer>

												)
											}
										]}
									/>

									<div id="text"></div>


									<Clearfix />
								</CardBody>
							</Card>
						}
					</GridItem>



				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		integrationByIdData: state.integration.integrationByIdData,
		lsDataTypes: state.integration.lsDataTypes,
		integrationDataFailed: state.integration.integrationDataFailed,
		dataTypesFaield: state.integration.dataTypesFaield,
		integrationBlobData: state.integration.integrationBlobData
	};
}

const mapDispatchToProps = dispatch => bindActionCreators(
	{ GetIntegrationById, GetDataTypes, GetIntegrationBlobById, ShowLoader, HideLoader }
	, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(userProfileStyles),
	withTranslation(),
)(Detail);
