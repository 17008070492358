import React from "react";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import Alert from "react-bootstrap-sweetalert";
import cx from "classnames";

//Material-table

// @material-ui/core components
import { Switch, Icon, FormControl, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Edit, Close, Check } from "@material-ui/icons";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import styles from './data-type.module.scss';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DocumentTitle from 'react-document-title';
import Loader from "components/Loader/Loader.jsx";
import Heading from "components/Heading/Heading.jsx"

// Utils
import compose from "utils/compose";
import { iconClasses } from "utils/utils";
// REDIX INIT
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GetDataTypes, UpdateDataType, ShowLoader, HideLoader, SweetAlert, HideAlert } from 'redux/actions';

// MOMENT JS
import * as moment from 'moment';
import CommonPage from "../../components/Pages/CommonPage";

class DataType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTypeTable: [],
            dataTypeForm: {},
            alert: null,
        };

        moment.locale(localStorage.getItem('i18nextLng').toLowerCase());
    }

    componentDidMount() {
        this.props.ShowLoader(<Loader />);
        this.props.GetDataTypes();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lsDataTypes !== this.props.lsDataTypes) {
            //this.setState({ dataTypeForm: this.props.appDataTypeData })
            this.setState({ dataTypeTable: this.handleDataTypeInfo(_.orderBy(this.props.lsDataTypes.items, ['description.order'], ['asc'])) });
            this.props.HideLoader();
            this.props.HideAlert();

        }
        if (prevProps.appDataTypeDataFailed !== this.props.appDataTypeDataFailed) {
            console.log('appDataTypeDataFailed:', this.props.appDataTypeDataFailed)
            this.props.HideLoader();
        }
        if (prevProps.updateDataType !== this.props.updateDataType) {
            this.props.SweetAlert(
                <Alert
                    success
                    title="Módulo atualizado com sucesso!"
                    onConfirm={() => this.props.GetDataTypes()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.primary
                    }
                    showCancel={false}
                >
                </Alert>
            );
        }
    }

    handleDataTypeInfo = (arrData) => {
        arrData.map(x => x['disabledRow'] = true)
        return arrData;
    }

    handleChange = (item, event) => {
        const key = event.target.id || event.target.name;
        const checked = event.target.checked;

        this.props.SweetAlert(
            <Alert
                warning
                style={{ display: "block" }}
                title="Alterar status dos módulos cadastrados"
                onConfirm={x => {
                    item["description"][key] = checked;
                    this.handleSubmit(item);
                    this.props.HideAlert();
                }}
                onCancel={() => this.props.HideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.primary
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.warning
                }
                confirmBtnText="Sim"
                cancelBtnText="Não"
                showCancel
            >
                Você deseja <b>{checked ? 'ativar' : 'desativar'}</b> este módulo cadastrado?
			  </Alert>
        );
    }

    toggleShownRowById = (item) => {
        let dataTable = this.state.dataTypeTable;
        let index = _.findIndex(dataTable, { id: item.id })
        item.disabledRow = !item.disabledRow;
        dataTable.splice(index, 1, item)
        this.setState({
            dataTypeTable: dataTable
        })
    }

    handleSubmit = (item) => {
        this.props.ShowLoader(<Loader />)
        this.props.UpdateDataType(item);
    }

    handleChangeOrder = (item, type, dataTable) => {
        let lastIndex = _.findLastIndex(dataTable);
        let objIndex = _.findIndex(dataTable, { id: item.id })
        let objFound = _.find(dataTable, (item, index) => index === objIndex);
        if (type) {
            if (objFound.description.order !== 0) {
                let secObj = _.find(dataTable, (item, index) => index === (objIndex - 1));

                objFound['description']['order'] = (objIndex - 1);
                secObj['description']['order'] = objIndex;

                this.props.UpdateDataType(objFound);
                this.props.UpdateDataType(secObj);
            } else {
                this.props.SweetAlert(
                    <Alert
                        warning
                        style={{ display: "block" }}
                        title="Não é possível alterar a ordem deste módulo!"
                        onConfirm={() => this.props.HideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText="OK"
                        showCancel={false}
                    >
                    </Alert>
                );
            }
        } else if (!type) {
            if (objFound.description.order !== lastIndex) {
                let secObj = _.find(dataTable, (item, index) => index === (objIndex + 1));

                objFound['description']['order'] = (objIndex + 1);
                secObj['description']['order'] = objIndex;

                this.props.UpdateDataType(objFound);
                this.props.UpdateDataType(secObj);
            } else {
                this.props.SweetAlert(
                    <Alert
                        warning
                        style={{ display: "block" }}
                        title="Não é possível alterar a ordem deste módulo!"
                        onConfirm={() => this.props.HideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.primary
                        }
                        confirmBtnText="OK"
                        showCancel={false}
                    >
                    </Alert>
                );
            }
        }
    }

    change = (item, event) => {
        let key = event.target.id || event.target.name;
        let value = event.target.value;

        let dataTable = this.state.dataTypeTable;
        let index = _.findIndex(dataTable, { id: item.id });
        item["description"][key] = value;
        dataTable.splice(index, 1, item)
        this.setState({
            dataTypeTable: dataTable
        })
    }

    render() {
        const { t, classes, } = this.props;
        const { dataTypeTable } = this.state;

        return (
            <CommonPage>
                {this.state.alert}
                <DocumentTitle title={'Connectyse - Tipos de Dados'} />
                <GridContainer>
                    <GridItem xs={12}>
                        <Heading
                            textAlign="left"
                            title={t("MENU_DATA_TYPE")}
                        />
                        <Card>
                            <CardHeader>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 className={classes.cardIconTitle}>
                                            <strong><b>Módulos cadastrados</b></strong>
                                        </h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem sm={12} xl={12} md={12}>
                                        {/* {this.state.outputLogData && this.state.outputLogData.length > 0 ? */}
                                        <div style={{ whiteSpace: 'pre' }}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Ordem</TableCell>
                                                        <TableCell align="center">Ícone</TableCell>
                                                        <TableCell align="left">Título</TableCell>
                                                        <TableCell align="right">Habilitado</TableCell>
                                                        <TableCell align="right">Visível</TableCell>
                                                        <TableCell align="center">Ações</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dataTypeTable.length > 0 && dataTypeTable.map(row => (
                                                        <TableRow key={row.id}>
                                                            <TableCell style={{ padding: '0 40px 0 16px' }}>
                                                                <div style={{ display: 'flex', height: '48px' }}>
                                                                    <div style={{ display: 'grid' }}>
                                                                        <Icon style={{ cursor: 'pointer' }} onClick={this.handleChangeOrder.bind(this, row, true, dataTypeTable)}>keyboard_arrow_up</Icon>
                                                                        <Icon style={{ cursor: 'pointer' }} onClick={this.handleChangeOrder.bind(this, row, false, dataTypeTable)}>keyboard_arrow_down</Icon>
                                                                    </div>
                                                                    <div style={{ lineHeight: '48px', marginLeft: '20px', color: '#b2b2b2' }}>
                                                                        {(row.description.order + 1)}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell style={{ padding: '0 20px 0 0' }} align="right">
                                                                <FormControl
                                                                    fullWidth
                                                                    className={cx(classes.selectTableFormControl, classes.selectFormControl, {
                                                                        [classes.disabled]: row.disabledRow,
                                                                    })}
                                                                    style={{ margin: "1px 0 8px 0 !important", paddingBottom: row.disabledRow ? '5px' : '23px' }}
                                                                >
                                                                    <Select
                                                                        disabled={row.disabledRow}
                                                                        id="iconUri"
                                                                        name="iconUri"
                                                                        MenuProps={{
                                                                            className: classes.selectMenu
                                                                        }}
                                                                        classes={{
                                                                            select: classes.select,
                                                                        }}
                                                                        value={row.description.iconUri}
                                                                        style={{ textAlign: 'center', width: '100%', padding: '12px 0 0' }}

                                                                        inputProps={{
                                                                            onChange: this.change.bind(null, row)
                                                                        }}
                                                                    >
                                                                        {iconClasses && _.map(iconClasses, item =>
                                                                            <MenuItem
                                                                                key={row.id}                                                                                // id={item.id}
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value={item}
                                                                                className={classes.menuItem}
                                                                            >
                                                                                <span className={item + " " + styles.spanIcon}></span>
                                                                            </MenuItem>)
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell style={{ padding: '0 40px 0 16px' }} align="left">
                                                                <div style={{ display: 'grid' }}>
                                                                    <CustomInput
                                                                        disabled={row.disabledRow}
                                                                        id="title"
                                                                        name="title"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            style: { margin: '0', paddingTop: '4px' }
                                                                        }}
                                                                        inputProps={{
                                                                            value: row.description.title,
                                                                            disabled: row.disabledRow,
                                                                            type: "text",
                                                                            onChange: this.change.bind(null, row)
                                                                        }}
                                                                    />
                                                                    <small style={{ color: '#aaa', fontStyle: 'italic', display: row.disabledRow ? 'block' : 'none' }}>
                                                                        {row.identifier}
                                                                    </small>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell style={{ padding: '0 40px 0 16px' }} align="right">
                                                                <Switch
                                                                    name="enabled"
                                                                    id="enabled"
                                                                    checked={row.description.enabled}
                                                                    onChange={this.handleChange.bind(null, row)}
                                                                    classes={{
                                                                        switchBase: this.props.classes.switchBase,
                                                                        checked: this.props.classes.switchChecked,
                                                                        thumb: this.props.classes.switchIcon,
                                                                        track: this.props.classes.switchBar,
                                                                        disabled: this.props.classes.disabled
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Switch
                                                                    name="visible"
                                                                    id="visible"
                                                                    checked={row.description.visible}
                                                                    onChange={this.handleChange.bind(null, row)}
                                                                    classes={{
                                                                        switchBase: this.props.classes.switchBase,
                                                                        checked: this.props.classes.switchChecked,
                                                                        thumb: this.props.classes.switchIcon,
                                                                        track: this.props.classes.switchBar,
                                                                        disabled: this.props.classes.disabled
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {row.disabledRow ?
                                                                    <Button
                                                                        justIcon
                                                                        size="sm"
                                                                        color="primary"
                                                                        onClick={this.toggleShownRowById.bind(this, row)}
                                                                    >
                                                                        <Edit className={classes.icons} />
                                                                    </Button>
                                                                    :
                                                                    <div>
                                                                        <Button
                                                                            justIcon
                                                                            size="sm"
                                                                            color="primary"
                                                                            onClick={this.handleSubmit.bind(this, row)}
                                                                        >
                                                                            <Check className={classes.icons} />
                                                                        </Button>
                                                                        <Button
                                                                            justIcon
                                                                            size="sm"
                                                                            color="danger"
                                                                            onClick={this.toggleShownRowById.bind(this, row)}>
                                                                            <Close className={classes.icons} />
                                                                        </Button>
                                                                    </div>
                                                                }

                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        {/* : 'Não foram gerados logs de integração'} */}
                                    </GridItem>

                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>
                </GridContainer>
            </CommonPage>
        );
    }
}

DataType.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        lsDataTypes: state.appDataType.lsDataTypes,
        updateDataType: state.appDataType.updateDataType,

        updateDataTypeFailed: state.appDataType.updateDataTypeFailed,
        dataTypesFailed: state.appDataType.dataTypesFailed

    };
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { GetDataTypes, UpdateDataType, ShowLoader, HideLoader, SweetAlert, HideAlert }
    , dispatch);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sweetAlertStyle),
    withTranslation(),
)(DataType);